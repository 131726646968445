export enum MessageStatusEnum {
  Sent = 1,
  Failed = 2,
  Delivered = 3,
  Read = 4,
  Queued = 5,
  Unknown = 6,
}

export const displayReadableMessageStatusEnum = (value: MessageStatusEnum) => {
  switch (value) {
    case MessageStatusEnum.Sent:
      return 'Sent';
    case MessageStatusEnum.Failed:
      return 'Failed';
    case MessageStatusEnum.Delivered:
      return 'Delivered';
    case MessageStatusEnum.Read:
      return 'Read';
    case MessageStatusEnum.Queued:
      return 'Queued';
    case MessageStatusEnum.Unknown:
      break;
    default:
      return 'Unknown';
  }
};

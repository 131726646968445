import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SendPensionsBeneficiaryMessageRequest } from '@app/communications/models/send-pensions-beneficiary-message-request';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';
import { PensionsBeneficiarySmsForm } from '@app/communications/models/pensions-beneficiary-sms-form';

@Injectable()
export class PensionsSmsModalFormService {
  form: FormGroup<PensionsBeneficiarySmsForm>;

  constructor(private formBuilder: FormBuilder) {
    const maxSmsLength = 160;
    this.form = this.formBuilder.group<PensionsBeneficiarySmsForm>({
      messageType: new FormControl<MessageTypeEnum | null>(null, {
        validators: Validators.required,
        nonNullable: true,
      }),
      pensionsBeneficiaryContactId: new FormControl<number>(0, {
        validators: Validators.required,
        nonNullable: true,
      }),
      systemContactId: new FormControl<number>(0, {
        validators: Validators.required,
        nonNullable: true,
      }),
      template: new FormControl<string>('', {
        validators: Validators.maxLength(maxSmsLength),
        nonNullable: true,
      }),
      characterCount: new FormControl<number>(0),
    });
    this.form.controls.template.disable();
  }

  populateRequestModelFromForm(
    conversationId: number,
    beneficiaryId: number,
    message: string,
  ): SendPensionsBeneficiaryMessageRequest {
    const formValues = this.form.getRawValue();
    return {
      ...formValues,
      beneficiaryId,
      message,
      conversationId,
    };
  }

  clear() {
    this.form.controls.messageType.setValue(null);
    this.form.controls.pensionsBeneficiaryContactId.setValue(null);
    this.form.controls.systemContactId.setValue(null);
    this.form.controls.characterCount.setValue(0);
  }

  patchPensionsBeneficiaryContact(value: number) {
    this.form.controls.pensionsBeneficiaryContactId.patchValue(value);
  }

  patchSender(value: number) {
    this.form.controls.systemContactId.patchValue(value);
  }
}

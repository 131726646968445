import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-whatsapp-chat-heading',
  templateUrl: './whatsapp-chat-heading.component.html',
  styleUrls: ['./whatsapp-chat-heading.component.scss'],
})
export class WhatsappChatHeadingComponent {
  @Input()
  contactFirstName: string;

  @Input()
  contactSurname: string;

  @Input()
  contactNumber: string;

  @Input()
  systemContactName: string;
}

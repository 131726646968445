import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WhatsappChatComponent } from '@app/communications/shells/whatsapp-chat/whatsapp-chat.component';
import { WhatsappConversationComponent } from '@app/communications/shells/whatsapp-conversation/whatsapp-conversation.component';
import { EmptyWhatsappChatComponent } from '@app/communications/components/empty-whatsapp-chat/empty-whatsapp-chat.component';

const routes: Routes = [
  {
    path: 'whatsapp-chat',
    component: WhatsappChatComponent,
    title: 'WhatsApp',
    children: [
      {
        path: 'conversations/:id',
        component: WhatsappConversationComponent,
      },
      {
        path: 'conversations',
        component: EmptyWhatsappChatComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommunicationsRoutingModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WhatsappMessageHistory } from '@app/communications/models/whatsapp-message-history';
import { WhatsAppConversationListFormService } from '@app/communications/components/whatsapp-conversations-list/services/whats-app-conversation-list-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { debounce, takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-whatsapp-conversations-list',
  templateUrl: './whatsapp-conversation-list.component.html',
  styleUrls: ['./whatsapp-conversation-list.component.scss'],
  providers: [WhatsAppConversationListFormService],
})
export class WhatsAppConversationsListComponent
  extends AutoUnsubscription
  implements OnInit
{
  @Input()
  conversations: WhatsappMessageHistory[];
  @Output()
  searchEmitter = new EventEmitter<string>();

  placeholderText = 'Search conversations';
  form: UntypedFormGroup = this.formService.form;

  constructor(private formService: WhatsAppConversationListFormService) {
    super();
  }

  ngOnInit(): void {
    this.form.controls.search.valueChanges
      .pipe(
        debounce(() => timer(200)),
        takeUntil(this.notifier),
      )
      .subscribe((value) => {
        this.searchEmitter.emit(value);
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { PensionsSmsModalFormService } from '@app/communications/components/pensions-sms-modal/services/pensions-sms-modal-form.service';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { SelectOption } from '@app/core/models/selector-option';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import {
  displayReadableMessageTypeEnum,
  MessageTypeEnum,
} from '@app/communications/models/message-type.enum';
import { objectToOptionsWithDisplay } from '@app/core/helpers/object-to-options';
import { map, takeUntil } from 'rxjs/operators';
import { Department } from '@app/communications/models/department.enum';
import { displayRelationship } from '@app/core/models/personal-relationship';
import { PensionsBeneficiaryContactDetails } from '@app/communications/models/pensions-beneficiary-contact-details';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-pensions-sms-modal',
  templateUrl: './pensions-sms-modal.component.html',
  styleUrl: './pensions-sms-modal.component.scss',
  providers: [PensionsSmsModalFormService],
})
export class PensionsSmsModalComponent
  extends AutoUnsubscription
  implements OnInit
{
  beneficiaryId: number;
  claimId: number;
  contactDetails$ = this.facade.pensionsBeneficiaryContactDetails$;
  contactDetailsIsLoading$ =
    this.facade.pensionsBeneficiaryContactDetailsIsLoading$;
  contactDetailsOptions: SelectOption[];
  isSendingSms$ = this.facade.isSendingSms$;
  isStartingConversation$ = this.facade.isStartingConversation$;
  isSending$ = combineLatest([
    this.isSendingSms$,
    this.isStartingConversation$,
  ]).pipe(
    map(
      ([isSendingSms, isStartingConversation]) =>
        isSendingSms || isStartingConversation,
    ),
  );
  form = this.formService.form;

  metadata$ = this.facade.userMessagingMetadata$;
  metadata: UserMessagingMetadata;
  senderOptions: SelectOption[];

  contactDetails: PensionsBeneficiaryContactDetails[] = [];
  systemContactId?: number;
  public selectedSmsTemplatePreview$ =
    this.facade.selectedPensionsBeneficiarySmsTemplatePreview$;
  messagePreview: string;
  messageTypeOptions: SelectOption[] = [];
  characterCount: number;
  shouldCreateConversation: boolean;
  conversationId: number | undefined;
  contactNumber: string;

  hasValidContact = false;
  hasValidSystemContact = false;

  constructor(
    private facade: CommunicationsFacade,
    private formService: PensionsSmsModalFormService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.facade.loadUserMessagingMetadata();
    this.setupMessageTypes();
    this.handleMetadataChanges();
    this.handleClaimContactChanges();
    this.handleTemplateChanges();
    this.handleConversationChanges();
  }

  private setupMessageTypes() {
    const validSmsMessageTypes = new Set<MessageTypeEnum>();
    validSmsMessageTypes.add(MessageTypeEnum.InformationRequest);
    validSmsMessageTypes.add(MessageTypeEnum.PleaseCallMe);

    this.messageTypeOptions = objectToOptionsWithDisplay(MessageTypeEnum, (x) =>
      displayReadableMessageTypeEnum(x),
    ).filter((x) => validSmsMessageTypes.has(x.value as number));
  }

  private handleMetadataChanges() {
    this.metadata$.pipe(takeUntil(this.notifier)).subscribe((metadata) => {
      if (metadata.data) {
        this.metadata = metadata.data;
        this.systemContactId = this.metadata.contacts.find(
          (contact) => contact.departmentId === Department.Pensions,
        ).systemContactId;
        this.senderOptions = metadata.data.contacts
          .filter(
            (contact) =>
              contact.departmentId === Department.Pensions && contact.isActive,
          )
          .map((contact) => ({
            value: contact.systemContactId,
            description: `${contact.name} ${contact.phoneNumber}`,
          }));
        this.hasValidSystemContact = this.senderOptions.length > 0;
        if (this.hasValidSystemContact) {
          this.formService.patchSender(+this.senderOptions[0].value);
        }
      }
    });
  }

  private handleClaimContactChanges() {
    this.contactDetails$
      .pipe(takeUntil(this.notifier))
      .subscribe((contactDetails) => {
        if (contactDetails) {
          this.contactDetails = contactDetails;
          this.contactDetailsOptions = contactDetails
            .filter((contact) => contact.isValidPhoneNumber)
            .map((details) => ({
              value: details.pensionsBeneficiaryContactId,
              description: `${details.name} ${details.surname} (${displayRelationship(details.relationship)}) ${details.phoneNumber}`,
            }));
          this.hasValidContact = this.contactDetailsOptions.length > 0;
          if (this.hasValidContact) {
            this.formService.patchPensionsBeneficiaryContact(
              +this.contactDetailsOptions[0].value,
            );
          }
        }
      });
  }

  private handleConversationChanges() {
    this.form.valueChanges
      .pipe(takeUntil(this.notifier))
      .subscribe((formValue) => {
        const contactId = formValue.pensionsBeneficiaryContactId;
        const systemContactId = formValue.systemContactId;
        const contact = this.contactDetails.find(
          (contact) => contact.pensionsBeneficiaryContactId === contactId,
        );
        const systemContact = this.metadata.contacts?.find(
          (contact) => contact.systemContactId === systemContactId,
        );
        const conversation = contact?.conversations.find(
          (conversation) =>
            (conversation.systemContactId === systemContactId ||
              conversation.systemContactPhoneNumber ==
                systemContact.phoneNumber) &&
            (conversation.hasSMSMessages || conversation.hasWhatsAppMessages),
        );
        this.shouldCreateConversation = !conversation;
        this.conversationId = conversation?.conversationId;
        this.beneficiaryId = contact?.beneficiaryId;
      });
  }

  private handleTemplateChanges() {
    this.facade.clearPensionsSmsMessageTemplatePreview();

    this.form.controls.messageType.valueChanges
      .pipe(takeUntil(this.notifier))
      .subscribe((id) => {
        if (id) {
          this.facade.loadPensionsBeneficiarySmsMessageTemplatePreview(
            id,
            this.claimId,
          );
        }
      });
    this.selectedSmsTemplatePreview$
      .pipe(takeUntil(this.notifier))
      .subscribe((messagePreview) => {
        this.messagePreview = messagePreview;
        this.characterCount = messagePreview.length;
      });
  }

  clear() {
    this.formService.clear();
    this.facade.clearPensionsSmsMessageTemplatePreview();
  }

  submit() {
    this.facade.sendPensionsBeneficiarySms(
      this.formService.populateRequestModelFromForm(
        this.conversationId,
        this.beneficiaryId,
        this.messagePreview,
      ),
      this.shouldCreateConversation,
    );
  }
}

export enum Department {
  Underwriting = 1,
  Registry = 2,
  Claims = 3,
  MedicalServices = 4,
  Pensions = 5,
  EAP = 6,
  CustomerServices = 7,
  Reception = 8,
  Finance = 9,
}

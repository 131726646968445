<app-dashboard-table
  [filterEnabled]="false"
  [searchEnabled]="false"
  [showPills]="tableDefinitions.length > 1"
  [showSearch]="true"
  [stretchColumns]="true"
  [tableDefinitions]="tableDefinitions">
</app-dashboard-table>
<ng-template #typeTemplate let-row="row" let-value="value">
  <span>{{ displayReadableMessage(value) }}</span>
</ng-template>
<ng-template #statusTemplate let-row="row" let-value="value">
  <span>{{ displayReadableStatusMessage(value) }}</span>
</ng-template>
<ng-template #headerTemplate>
  <app-icon-button
    (click)="sendWhatsApp()"
    *ngIf="showWhatsAppSender"
    [iconSize]="iconSize"
    [icon]="'whatsapp'"
    class="me-5"></app-icon-button>
  <app-icon-button
    (click)="sendSMS()"
    *ngIf="showSmsSender"
    [iconSize]="iconSize"
    [icon]="'sms'"
    class="me-5"></app-icon-button>
</ng-template>

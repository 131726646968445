<div class="chat-container p-2">
  <ng-content select="[template-message]"></ng-content>
  <div
    *ngFor="let message of messages; trackBy: trackMessage"
    [ngClass]="message.isOutboundMessage ? 'outbound' : 'inbound'"
    [@fadeInOut]
    class="d-flex flex-column message-container p-1">
    <div class="message shadow-sm rounded d-flex flex-column">
      <div class="pe-2 ps-2 pt-2 pb-1">
        <div
          class="d-flex flex-row file-elements"
          [class.mb-1]="message.content !== null">
          <app-media-player
            [message]="message"
            [mediaTypes]="mediaTypes"
            (downloadMedia)="emitDownloadMedia($event)"
            *ngIf="message.hasMedia"></app-media-player>
          <div
            *ngIf="message.hasMedia && !message.isAudioMedia"
            class="action-button">
            <app-action-dropdown
              *ngIf="!message.isMediaDownloading; else downloading"
              [actions]="fileActions"
              [row]="message"></app-action-dropdown>
            <ng-template #downloading>
              <div class="spinner-border spinner-border-sm text-primary"></div>
            </ng-template>
          </div>
        </div>

        <div *ngIf="message.content !== null" class="message-content">
          <span>{{ message.content }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end pb-1 pe-1 ps-4">
        <span class="date">{{
          message.sentDate | date: 'dd-MM-yyyy HH:mm'
        }}</span>
        <div
          *ngIf="message.isOutboundMessage"
          [ngSwitch]="message.status"
          class="status-icon-container d-flex ms-2">
          <span
            *ngSwitchCase="messageStatusEnum.Failed"
            class="fem-icon fem-icon-error error"></span>
          <span
            *ngSwitchCase="messageStatusEnum.Sent"
            class="fem-icon fem-icon-done"></span>
          <span
            *ngSwitchCase="messageStatusEnum.Delivered"
            class="fem-icon fem-icon-done-all"></span>
          <span
            *ngSwitchCase="messageStatusEnum.Read"
            class="fem-icon fem-icon-done-all read"></span>
          <span
            *ngSwitchCase="messageStatusEnum.Queued"
            class="fem-icon fem-icon-clock"></span>
          <span *ngSwitchDefault class="fem-icon fem-icon-help-circle"></span>
        </div>
      </div>
    </div>
    <div *ngIf="message.isOutboundMessage" class="sender mt-1">
      {{ message.sender }}
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { ClaimSmsModalComponent } from '@app/communications/components/claim-sms-modal/claim-sms-modal.component';
import { errorless } from '@app/core/helpers/errorless';
import { ClaimWhatsappModalComponent } from '@app/communications/components/claim-whatsapp-modal/claim-whatsapp-modal.component';
import { Claim } from '@app/claims-administration/claims/models/claim';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Department } from '@app/communications/models/department.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { filterOutNulls } from '@app/core/helpers/array-helpers';
import { SystemContact } from '@app/communications/models/system-contact';
import { ClaimContactDetails } from '@app/communications/models/claim-contact-details';

@Component({
  selector: 'app-claim-communications',
  templateUrl: './claim-communications.component.html',
  styleUrls: ['./claim-communications.component.scss'],
})
export class ClaimCommunicationsComponent
  extends AutoUnsubscription
  implements OnInit
{
  @Input()
  claim: Claim;
  claimContactsIsLoading$ = this.facade.claimContactDetailsIsLoading$;
  claimContacts$ = this.facade.claimContactDetails$;
  systemContactsIsLoading$ = this.facade.systemContactDetailsIsLoading$;
  systemContacts$ = this.facade.systemContactDetails$;
  receivers$: Observable<string[]>;
  senders$: Observable<string[]>;
  isInitialLoad$ = combineLatest([
    this.claimContacts$,
    this.systemContacts$,
  ]).pipe(
    map(([claimContacts, systemContacts]) => !claimContacts || !systemContacts),
    takeUntil(this.notifier),
  );

  constructor(
    private modalService: NgbModal,
    private facade: CommunicationsFacade,
  ) {
    super();
  }

  ngOnInit(): void {
    this.facade.loadClaimContactDetails(this.claim.claimCode);
    this.facade.loadSystemContacts();
    this.receivers$ = this.claimContacts$.pipe(
      filterOutNulls,
      switchMap((contacts: ClaimContactDetails[]) =>
        of(contacts.map((contact) => contact.phoneNumber)),
      ),
      takeUntil(this.notifier),
    );
    this.senders$ = this.systemContacts$.pipe(
      filterOutNulls,
      switchMap((contacts: SystemContact[]) =>
        of(
          contacts
            .filter(
              (contact) =>
                contact.departments.includes(Department.Claims) ||
                contact.departments.includes(Department.Registry),
            )
            .map((contact) => contact.phoneNumber),
        ),
      ),
      takeUntil(this.notifier),
    );
  }

  showSmsModal() {
    const modal = this.modalService.open(ClaimSmsModalComponent);
    modal.componentInstance.claimCode = this.claim.claimCode;
    return errorless(modal.result);
  }

  openWhatsAppModal() {
    const modal = this.modalService.open(ClaimWhatsappModalComponent);
    modal.componentInstance.claimCode = this.claim.claimCode;
    return errorless(modal.result);
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { SortPropDir } from '@swimlane/ngx-datatable';
import {
  ComplexSearchRequest,
  initialComplexSearchRequest,
  initialSearchRequest,
  SearchOperand,
  SearchRequest,
} from '@app/shared/search/interfaces/search-request';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import { ComplexSearchRequestBuilder } from '@app/shared/search/builders/complex-search-request.builder';
import { SearchRequestBuilder } from '@app/shared/search/builders/search-request.builder';
import { FilterParameterOperator } from '@app/shared/search/interfaces/filter-parameter';
import { WhatsappMessageHistory } from '@app/communications/models/whatsapp-message-history';
import { prepareComplexParameters } from '@app/core/http/http-resource-helper';
import { WhatsappConversationDetails } from '@app/communications/models/whatsapp-conversation-details';
import { MessageHistory } from '@app/communications/models/message-history';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';
import { Message } from '@app/communications/models/message';
import { SendClaimMessageRequest } from '@app/communications/models/send-claim-message-request';
import { SendWhatsAppMessageRequest } from '@app/communications/models/send-whatsapp-message-request';
import { AllowedWhatsAppFileTypes } from '@app/communications/models/allowed-whatsapp-file-types';
import { TemplatedMessage } from '@app/communications/models/templated-message';
import { WhatsappTemplate } from '@app/communications/models/whatsapp-template.enum';
import { HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { SystemContact } from '@app/communications/models/system-contact';
import { SendSmsRequest } from '@app/communications/models/send-sms-request';
import { SendPensionsBeneficiaryMessageRequest } from '@app/communications/models/send-pensions-beneficiary-message-request';
import { SendEducationAssistanceMessageRequest } from '@app/communications/models/send-education-assistance-message-request';
import { HubConnection } from '@microsoft/signalr';
import { formatClaimCode } from '@app/shared/helpers/claim-code-formatter';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { UnreadWhatsappConversationsNotification } from '@app/communications/models/unread-whatsapp-conversations.notification';
import { NotificationType } from '@app/communications/models/notification-types.enum';

@Injectable()
export class CommunicationsService {
  constructor(
    private httpService: HttpService,
    private notificationsService: NotificationsService,
  ) {}

  communicationsNotificationHub: HubConnection | undefined;
  getUserMessagingMetadata = () =>
    this.httpService.get('communications/user/metadata');
  getUserMessageHistory = (
    metadata: UserMessagingMetadata,
    offset: number,
    limit: number,
    sortParams: SortPropDir,
    searchRequest: SearchRequest = initialSearchRequest,
  ) => {
    const searchData: ComplexSearchRequest =
      new ComplexSearchRequestBuilder<WhatsappMessageHistory>()
        .addSearchRequest(
          new SearchRequestBuilder<WhatsappMessageHistory>()
            .addNewNonGenericFilterParameter(
              'participants',
              metadata.username,
              FilterParameterOperator.Equals,
            )
            .build(),
        )
        .addSearchRequest(searchRequest)
        .build();
    return this.httpService.get(
      'communications/whatsapp/conversations',
      prepareComplexParameters({
        offset,
        limit,
        sortParams,
        searchData,
        useSortBy: true,
      }),
    );
  };

  getConversationDetails = (conversationId: number) =>
    this.httpService.get<WhatsappConversationDetails>(
      `communications/whatsapp/conversations/${conversationId}`,
    );

  getMessageHistory = (
    messageTypes: MessageTypeEnum[],
    senders: string[],
    receivers: string[],
    offset: number,
    limit: number,
    sortParams: SortPropDir,
    searchRequest: SearchRequest = initialSearchRequest,
    complexSearchRequest: ComplexSearchRequest = initialComplexSearchRequest,
  ) => {
    const sendersSearchRequest = new SearchRequestBuilder<MessageHistory>()
      .addNewNonGenericFilterParameter(
        'senders',
        senders.toString(),
        FilterParameterOperator.Equals,
      )
      .build();
    const receiversSearchRequest = new SearchRequestBuilder<MessageHistory>()
      .addNewNonGenericFilterParameter(
        'receivers',
        receivers.toString(),
        FilterParameterOperator.Equals,
      )
      .build();
    const messageTypesSearchRequestBuilder =
      new SearchRequestBuilder<MessageHistory>();
    for (const messageType of messageTypes) {
      messageTypesSearchRequestBuilder.addNewFilterParameter(
        'messageType',
        messageType,
        FilterParameterOperator.Equals,
      );
    }
    messageTypesSearchRequestBuilder.setOperand(SearchOperand.OR);
    const searchRequestBuilder =
      new ComplexSearchRequestBuilder<MessageHistory>();
    if (messageTypes.length > 0) {
      searchRequestBuilder.addSearchRequest(
        messageTypesSearchRequestBuilder.build(),
      );
    }
    searchRequestBuilder
      .addSearchRequest(sendersSearchRequest)
      .addSearchRequest(receiversSearchRequest)
      .addSearchRequest(searchRequest)
      .combine(complexSearchRequest);
    const searchData: ComplexSearchRequest = searchRequestBuilder.build();
    return this.httpService.get(
      'communications/message/history',
      prepareComplexParameters({
        offset,
        limit,
        sortParams,
        searchData,
        useSortBy: true,
      }),
    );
  };

  getConversationMessages = (
    conversationId: number,
    messageTypes: MessageTypeEnum[],
    offset: number,
    limit: number,
    searchRequest: SearchRequest = null,
  ) => {
    const messageTypesSearchRequestBuilder =
      new SearchRequestBuilder<Message>();

    for (const messageType of messageTypes) {
      messageTypesSearchRequestBuilder.addNewFilterParameter(
        'type',
        messageType,
        FilterParameterOperator.Equals,
      );
    }
    messageTypesSearchRequestBuilder.setOperand(SearchOperand.AND);

    const conversationIdSearchRequestBuilder =
      new SearchRequestBuilder<Message>();

    conversationIdSearchRequestBuilder.addNewFilterParameter(
      'conversationId',
      conversationId,
      FilterParameterOperator.Equals,
    );

    const searchRequestBuilder =
      new ComplexSearchRequestBuilder<MessageHistory>()
        .addSearchRequest(conversationIdSearchRequestBuilder.build())
        .addSearchRequest(messageTypesSearchRequestBuilder.build());

    if (searchRequest) {
      searchRequestBuilder.addSearchRequest(searchRequest);
    }

    const searchData = searchRequestBuilder.build();

    return this.httpService.get(
      'communications/message',
      prepareComplexParameters({
        offset,
        limit,
        sortParams: null,
        searchData,
        useSortBy: true,
      }),
    );
  };

  markConversationAsRead = (conversationId: number) =>
    this.httpService.patch(
      `communications/whatsapp/${conversationId}/mark-as-read`,
      {
        conversationId,
      },
    );

  sendWhatsAppMessage = (request: SendWhatsAppMessageRequest) =>
    this.httpService.post<Message>('communications/whatsapp', request);

  getClaimContactDetails(claimCode: string) {
    return this.httpService.get(
      `communications/contacts/claim/${formatClaimCode(claimCode)}`,
    );
  }

  startClaimConversation(request: SendClaimMessageRequest) {
    return this.httpService.post(`communications/message/claim`, request);
  }

  getAllowedWhatsAppFileTypes() {
    return this.httpService.get<AllowedWhatsAppFileTypes>(
      `communications/whatsapp/allowed-file-types`,
    );
  }

  getClaimSmsMessageTemplate = (
    messageType: MessageTypeEnum,
    claimCode: string,
  ) =>
    this.httpService.get('communications/sms/claim/template', {
      messageType,
      claimCode,
    });

  sendSms(request: SendSmsRequest) {
    return this.httpService.post(`communications/sms`, request);
  }

  downloadMediaFile(id: number): Observable<HttpResponse<Blob>> {
    return this.httpService.request(
      'GET',
      `communications/whatsapp/messages/${id}/media`,
      {
        responseType: 'blob',
        observe: 'response',
      },
    );
  }

  getTemplatedMessage(template: WhatsappTemplate, contactId: number) {
    return this.httpService.get<TemplatedMessage>(
      `communications/whatsapp/templated-messages/${template}`,
      {
        contactId,
      },
    );
  }

  getSystemContactDetails() {
    return this.httpService.get<SystemContact[]>(
      `communications/contacts/system`,
    );
  }

  startPensionsBeneficiaryConversation(
    request: SendPensionsBeneficiaryMessageRequest,
  ) {
    return this.httpService.post(`communications/message/pensions`, request);
  }

  getPensionsBeneficiaryContactDetails(claimId: number) {
    return this.httpService.get(`communications/contacts/pensions/${claimId}`);
  }

  getPensionsBeneficiarySmsMessageTemplate = (
    messageType: MessageTypeEnum,
    claimId: number,
  ) =>
    this.httpService.get('communications/sms/pensions/template', {
      messageType,
      claimId,
    });

  getEducationAssistanceContactDetails(beneficiaryId: number) {
    return this.httpService.get(
      `communications/contacts/education-assistance/${beneficiaryId}`,
    );
  }

  startEducationAssistanceConversation(
    request: SendEducationAssistanceMessageRequest,
  ) {
    return this.httpService.post(
      `communications/message/education-assistance`,
      request,
    );
  }

  startNotifications() {
    this.communicationsNotificationHub =
      this.notificationsService.createConnection(
        'communications/notifications',
      );
    return from(this.communicationsNotificationHub.start());
  }

  getWhatsAppNotifications(
    callBack: (message: UnreadWhatsappConversationsNotification) => void,
  ) {
    this.communicationsNotificationHub.on(
      NotificationType.UnreadWhatsApp,
      (message: UnreadWhatsappConversationsNotification) => callBack(message),
    );
    return from(
      this.communicationsNotificationHub.send(NotificationType.UnreadWhatsApp),
    );
  }
}

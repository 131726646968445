import { Component, OnInit } from '@angular/core';
import { PensionsWhatsappModalFormService } from '@app/communications/components/pensions-whatsapp-modal/services/pensions-whatsapp-modal-form.service';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SelectOption } from '@app/core/models/selector-option';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Department } from '@app/communications/models/department.enum';
import { displayRelationship } from '@app/core/models/personal-relationship';
import { PensionsBeneficiaryContactDetails } from '@app/communications/models/pensions-beneficiary-contact-details';
import { SendPensionsBeneficiaryMessageRequest } from '@app/communications/models/send-pensions-beneficiary-message-request';

@Component({
  selector: 'app-pensions-whatsapp-modal',
  templateUrl: './pensions-whatsapp-modal.component.html',
  styleUrl: './pensions-whatsapp-modal.component.scss',
  providers: [PensionsWhatsappModalFormService],
})
export class PensionsWhatsappModalComponent
  extends AutoUnsubscription
  implements OnInit
{
  beneficiaryId: number;
  isStartingConversation$ = this.facade.isStartingConversation$;
  isSendingOptIn$ = this.facade.isSendingOptIn$;
  contactDetails$ = this.facade.pensionsBeneficiaryContactDetails$;
  contactDetailsIsLoading$ =
    this.facade.pensionsBeneficiaryContactDetailsIsLoading$;
  isLoading$ = combineLatest([
    this.isStartingConversation$,
    this.isSendingOptIn$,
  ]).pipe(
    map(
      ([isStartingConversation, isSendingOptIn]) =>
        isStartingConversation || isSendingOptIn,
    ),
  );
  contactDetailsOptions: SelectOption[] = [];
  senderOptions: SelectOption[] = [];
  contactDetails: PensionsBeneficiaryContactDetails[] = [];
  systemContactId?: number;
  metadata$ = this.facade.userMessagingMetadata$;
  metadata: UserMessagingMetadata;
  form = this.formService.form;
  canSendOptIn = false;
  hasValidContact = false;
  hasValidSystemContact = false;

  conversationId?: number;

  constructor(
    private facade: CommunicationsFacade,
    private formService: PensionsWhatsappModalFormService,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.facade.loadUserMessagingMetadata();
    this.metadata$.pipe(takeUntil(this.notifier)).subscribe((metadata) => {
      if (metadata.data) {
        this.metadata = metadata.data;
        this.systemContactId = this.metadata.contacts?.find(
          (contact) => contact.departmentId === Department.Pensions,
        )?.systemContactId;
        this.senderOptions = metadata.data.contacts
          .filter(
            (contact) =>
              contact.departmentId === Department.Pensions && contact.isActive,
          )
          .map((contact) => ({
            value: contact.systemContactId,
            description: `${contact.name} ${contact.phoneNumber}`,
          }));
        this.hasValidSystemContact = this.senderOptions.length > 0;
        if (this.hasValidSystemContact) {
          this.formService.patchSender(+this.senderOptions[0].value);
        }
      }
    });

    this.contactDetails$
      .pipe(takeUntil(this.notifier))
      .subscribe((contactDetails) => {
        if (contactDetails) {
          this.contactDetailsOptions = contactDetails
            .filter(
              (contact) =>
                contact.isValidPhoneNumber && !contact.hasPendingEdit,
            )
            .map((details) => ({
              value: details.pensionsBeneficiaryContactId,
              description: `${details.name ?? ''} ${
                details.surname ?? ''
              } (${displayRelationship(details.relationship)}) ${
                details.phoneNumber
              }`,
            }));
          this.contactDetails = contactDetails;
          this.hasValidContact = this.contactDetailsOptions.length > 0;
          if (this.hasValidContact) {
            this.formService.patchPensionsBeneficiaryContact(
              +this.contactDetailsOptions[0].value,
            );
          }
        }
      });

    this.form.valueChanges
      .pipe(takeUntil(this.notifier))
      .subscribe((formValue) => {
        const contactId = formValue.pensionsBeneficiaryContactId;
        const systemContactId = formValue.systemContactId;
        const contact = this.contactDetails.find(
          (contact) => contact.pensionsBeneficiaryContactId === contactId,
        );
        const systemContact = this.metadata.contacts?.find(
          (contact) => contact.systemContactId === systemContactId,
        );
        const conversation = contact?.conversations.find(
          (conversation) =>
            (conversation.systemContactId === systemContactId ||
              conversation.systemContactPhoneNumber ==
                systemContact.phoneNumber) &&
            conversation.hasWhatsAppMessages,
        );
        this.canSendOptIn = !conversation;
        this.beneficiaryId = contact?.beneficiaryId;
        this.conversationId = conversation?.conversationId;
      });
  }

  sendOptIn() {
    if (this.form.valid && this.beneficiaryId) {
      const request: SendPensionsBeneficiaryMessageRequest =
        this.formService.populateRequestModelFromForm(this.beneficiaryId);
      this.facade.startPensionsBeneficiaryConversation(request);
    }
  }

  goToConversation() {
    if (this.form.valid && this.conversationId) {
      window.open(
        `/communications/whatsapp-chat/conversations/${this.conversationId}`,
        '_blank',
      );
      this.modalService.dismissAll();
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommunicationsService } from '@app/communications/services/communications.service';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ToastService } from '@app/core/services/toast.service';
import { ApiError } from '@app/core/models/ErrorResponse';
import { interval, merge, Observable, of } from 'rxjs';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';
import { StartClaimConversationResponse } from '@app/communications/models/start-claim-conversation-response';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ClaimContactDetails } from '@app/communications/models/claim-contact-details';
import {
  defaultLimit,
  defaultOffset,
} from '@app/shared/helpers/pagination-helpers';
import { SearchRequestBuilder } from '@app/shared/search/builders/search-request.builder';
import { Message } from '@app/communications/models/message';
import { FilterParameterOperator } from '@app/shared/search/interfaces/filter-parameter';
import { Store } from '@ngrx/store';
import { CommunicationsState } from '@app/communications/state/communications.state';
import { getWhatsappConversationMessages } from '@app/communications/state/communications.selectors';
import { AllowedWhatsAppFileTypes } from '@app/communications/models/allowed-whatsapp-file-types';
import { SmsMessageTemplateResponse } from '@app/communications/models/sms-message-template-response';
import saveAs from 'file-saver';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { TemplatedMessage } from '@app/communications/models/templated-message';
import { SystemContact } from '@app/communications/models/system-contact';
import { maxBy } from 'lodash';
import { PensionsBeneficiaryContactDetails } from '@app/communications/models/pensions-beneficiary-contact-details';
import { StartPensionsBeneficiaryConversationResponse } from '@app/communications/models/start-pensions-beneficiary-conversation-response';
import { StartEducationAssistanceConversationResponse } from '@app/communications/models/start-education-assistance-conversation-response';
import { EducationAssistanceBeneficiaryContactDetails } from '@app/communications/models/education-assistance-beneficiary-contact-details';
import { UnreadWhatsappConversationsNotification } from '@app/communications/models/unread-whatsapp-conversations.notification';
import * as communicationsActions from './communications.actions';

@Injectable()
export class CommunicationsEffects {
  constructor(
    private actions$: Actions,
    private communicationsService: CommunicationsService,
    private toastService: ToastService,
    private store$: Store<CommunicationsState>,
    private modalService: NgbModal,
    private router: Router,
    private configService: AppConfigService,
  ) {}

  loadUserMessagingMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadUserMessagingMetadata),
      switchMap(() =>
        this.communicationsService.getUserMessagingMetadata().pipe(
          map((response) =>
            communicationsActions.loadUserMessagingMetadataSuccess({
              response,
            }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Unable to get user messaging details - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.loadUserMessagingMetadataFailure({
                error: friendlyMessage,
              }),
            );
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(communicationsActions.loadUserMessagingMetadata),
            ),
          ),
        ),
      ),
    ),
  );
  loadUserMessageHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadUserMessageHistory),
      switchMap(
        ({ metadata, offset, limit, searchData, sort, complexSearchData }) =>
          this.communicationsService
            .getUserMessageHistory(metadata, offset, limit, sort, searchData)
            .pipe(
              map((response) =>
                communicationsActions.loadUserMessageHistorySuccess({
                  response,
                }),
              ),
              catchError(({ friendlyMessage }: ApiError) => {
                this.toastService.showError(
                  `Unable to get user message history - ${friendlyMessage}`,
                );
                return of(
                  communicationsActions.loadUserMessageHistoryFailure({
                    error: friendlyMessage,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(communicationsActions.loadUserMessageHistory),
                ),
              ),
            ),
      ),
    ),
  );

  loadWhatsappConversationsPeriodically = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadUserMessageHistory),
      switchMap(
        ({ metadata, offset, limit, searchData, sort, complexSearchData }) =>
          interval(
            this.configService.config.features.communicationsFeatures
              .messageUpdatePollingTime,
          ).pipe(
            map(() =>
              communicationsActions.loadUserMessageHistory({
                metadata,
                offset,
                limit,
                searchData,
                sort,
                complexSearchData,
              }),
            ),
            takeUntil(
              merge(
                this.actions$.pipe(
                  ofType(communicationsActions.loadUserMessageHistory),
                ),
                this.routerNavigatedAwayFromWhatsapp,
              ),
            ),
          ),
      ),
    ),
  );

  loadConversationDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadConversationDetails),
      switchMap(({ conversationId }) =>
        this.communicationsService.getConversationDetails(conversationId).pipe(
          map((response) =>
            communicationsActions.loadConversationDetailsSuccess({
              conversation: response,
            }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Unable to get conversation details - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.loadConversationDetailsFailure({
                error: friendlyMessage,
              }),
            );
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(communicationsActions.loadConversationDetails),
            ),
          ),
        ),
      ),
    ),
  );
  loadMessageHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadMessageHistory),
      switchMap(
        ({
          senders,
          receivers,
          messageType,
          offset,
          limit,
          searchData,
          sort,
          complexSearchData,
        }) =>
          this.communicationsService
            .getMessageHistory(
              messageType,
              senders,
              receivers,
              offset,
              limit,
              sort,
              searchData,
              complexSearchData,
            )
            .pipe(
              map((response) =>
                communicationsActions.loadMessageHistorySuccess({
                  response,
                }),
              ),
              catchError(({ friendlyMessage }: ApiError) => {
                this.toastService.showError(
                  `Unable to get message history - ${friendlyMessage}`, // TODO message type
                );
                return of(
                  communicationsActions.loadMessageHistoryFailure({
                    error: friendlyMessage,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(communicationsActions.loadMessageHistory),
                ),
              ),
            ),
      ),
    ),
  );
  loadWhatsappConversationMessages = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadWhatsappConversationMessages),
      switchMap(({ conversationId, offset, limit }) =>
        this.communicationsService
          .getConversationMessages(
            conversationId,
            [MessageTypeEnum.Whatsapp],
            offset,
            limit,
          )
          .pipe(
            map((response) =>
              communicationsActions.loadWhatsappConversationMessagesSuccess({
                conversationId,
                response,
              }),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Unable to get conversation messages - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.loadWhatsappConversationMessagesFailure({
                  error: friendlyMessage,
                }),
              );
            }),
          ),
      ),
    ),
  );
  sendWhatsAppMessage = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.sendWhatsAppMessage),
      switchMap(({ request }) =>
        this.communicationsService.sendWhatsAppMessage(request).pipe(
          map((message) =>
            communicationsActions.sendWhatsAppMessageSuccess({ message }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Unable to send WhatsApp message - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.sendWhatsAppMessageFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
  sendWhatAppMessageSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.sendWhatsAppMessageSuccess),
      map((action) =>
        communicationsActions.loadLatestMessagesForConversation({
          conversationId: action.message.conversationId,
        }),
      ),
    ),
  );
  refreshConversationDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadLatestMessagesForConversation),
      map((action) =>
        communicationsActions.loadConversationDetails({
          conversationId: action.conversationId,
        }),
      ),
    ),
  );
  loadLatestMessagesForConversation = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadLatestMessagesForConversation),
      withLatestFrom(this.store$.select(getWhatsappConversationMessages)),
      switchMap(([{ conversationId }, messages]) => {
        let searchRequest = null;

        if (messages.length > 0) {
          const messageTypesSearchRequestBuilder =
            new SearchRequestBuilder<Message>();

          const latestModifiedMessage = maxBy(
            messages,
            (m) => new Date(m.modifiedDate),
          );

          const latestDate = new Date(latestModifiedMessage.modifiedDate);

          latestDate.setTime(latestDate.getTime() + 1000);

          messageTypesSearchRequestBuilder.addNewFilterParameter(
            'modifiedDate',
            latestDate,
            FilterParameterOperator.GreaterThan,
          );
          searchRequest = messageTypesSearchRequestBuilder.build();
        }

        return this.communicationsService
          .getConversationMessages(
            conversationId,
            [MessageTypeEnum.Whatsapp],
            defaultOffset,
            defaultLimit,
            searchRequest,
          )
          .pipe(
            map((response) =>
              communicationsActions.loadLatestMessagesForConversationSuccess({
                conversationId,
                messages: response.data,
              }),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Unable to get conversation messages - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.loadLatestMessagesForConversationFailure({
                  error: friendlyMessage,
                }),
              );
            }),
          );
      }),
    ),
  );
  loadClaimSmsMessageTemplatePreview = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadClaimSmsMessageTemplatePreview),
      switchMap(({ messageTemplate, claimCode }) =>
        this.communicationsService
          .getClaimSmsMessageTemplate(messageTemplate, claimCode)
          .pipe(
            map((response: SmsMessageTemplateResponse) =>
              communicationsActions.loadClaimSmsMessageTemplatePreviewSuccess({
                template: response.template,
              }),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Unable to get claim sms message template - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.loadClaimSmsMessageTemplatePreviewFailure(
                  {
                    error: friendlyMessage,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );
  markWhatsappConversationAsRead = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.markWhatsappConversationAsRead),
      mergeMap(({ conversationId }) =>
        this.communicationsService.markConversationAsRead(conversationId).pipe(
          map((response) =>
            communicationsActions.markWhatsappConversationAsReadSuccess({
              conversationId,
            }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Unable to mark conversation as read - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.markWhatsappConversationAsReadFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  markConversationAsReadAfterView = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadWhatsappConversationMessagesSuccess),
      map(({ conversationId }) =>
        communicationsActions.markWhatsappConversationAsRead({
          conversationId,
        }),
      ),
    ),
  );

  markConversationAsReadWhileReading = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadLatestMessagesForConversationSuccess),
      map(({ conversationId, messages }) => {
        if (messages.length > 0) {
          return communicationsActions.markWhatsappConversationAsRead({
            conversationId,
          });
        }
        return communicationsActions.markWhatsappConversationAsReadSuccess({
          conversationId,
        });
      }),
    ),
  );

  routerNavigatedAwayFromWhatsapp = (
    this.router.events as Observable<RouterEvent>
  ).pipe(
    filter((event) => event instanceof NavigationEnd),
    filter(
      (event: RouterEvent) =>
        !event.url.includes('communications/whatsapp-chat/conversations'),
    ),
  ) as Observable<RouterEvent>;

  loadWhatsappConversationMessagesPeriodically = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadWhatsappConversationMessages),
      switchMap(({ conversationId }) =>
        interval(
          this.configService.config.features.communicationsFeatures
            .messageUpdatePollingTime,
        ).pipe(
          map(() =>
            communicationsActions.loadLatestMessagesForConversation({
              conversationId,
            }),
          ),
          takeUntil(
            merge(
              this.actions$.pipe(
                ofType(communicationsActions.loadWhatsappConversationMessages),
              ),
              this.routerNavigatedAwayFromWhatsapp,
            ),
          ),
        ),
      ),
    ),
  );

  loadClaimContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadClaimContactDetails),
      switchMap(({ claimCode }) =>
        this.communicationsService.getClaimContactDetails(claimCode).pipe(
          map((contactDetails: ClaimContactDetails[]) =>
            communicationsActions.loadClaimContactDetailsDataSuccess({
              response: contactDetails,
            }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not load contact details - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.loadClaimContactDetailsDataFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
  startClaimWhatsAppConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.startClaimWhatsAppConversation),
      switchMap(({ request }) =>
        this.communicationsService.startClaimConversation(request).pipe(
          switchMap((response: StartClaimConversationResponse) => [
            communicationsActions.startClaimWhatsAppConversationSuccess(),
            communicationsActions.sendWhatsAppOptIn({
              request: {
                ...request,
                conversationId: response.conversationId,
              },
            }),
          ]),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not start claim conversation - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.startClaimWhatsAppConversationFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
  startClaimSmsConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.startClaimSmsConversation),
      switchMap(({ request }) =>
        this.communicationsService.startClaimConversation(request).pipe(
          switchMap((response: StartClaimConversationResponse) => [
            communicationsActions.startClaimSmsConversationSuccess(),
            communicationsActions.sendSms({
              request: {
                ...request,
                conversationId: response.conversationId,
              },
            }),
          ]),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not start claim conversation - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.startClaimSmsConversationFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
  sendWhatsAppOptIn = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.sendWhatsAppOptIn),
      switchMap(({ request }) =>
        this.communicationsService.sendWhatsAppMessage(request).pipe(
          tap(() => {
            this.router.navigate([
              'communications',
              'whatsapp-chat',
              'conversations',
              request.conversationId,
            ]);
            this.modalService.dismissAll();
          }),
          map((response: StartClaimConversationResponse) =>
            communicationsActions.sendWhatsAppOptInSuccess(),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not send WhatsApp opt in - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.sendWhatsAppOptInFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
  sendSms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.sendSms),
      switchMap(({ request }) =>
        this.communicationsService.sendSms(request).pipe(
          tap(() => {
            this.modalService.dismissAll();
          }),
          map(() => communicationsActions.sendSmsSuccess()),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not send sms - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.sendSmsFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
  downloadMediaFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.downloadMediaFile),
      mergeMap(({ id }) =>
        this.communicationsService.downloadMediaFile(id).pipe(
          map((response) => {
            try {
              const fileContents = response.body;
              const contentDisposition = response.headers.get(
                'content-disposition',
              );
              const filename = contentDisposition
                .split(';')[1]
                .split('filename')[1]
                .split('=')[1]
                .trim();
              const blob = new Blob([fileContents], {
                type: fileContents.type,
              });
              saveAs(blob, filename);
            } catch (e) {
              console.error(e);
            }
            return communicationsActions.downloadMediaFileSuccess({ id });
          }),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not download file - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.downloadMediaFileFailure({
                error: friendlyMessage,
                id,
              }),
            );
          }),
        ),
      ),
    ),
  );
  allowedWhatsAppFileTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.getWhatsAppAllowedFileTypes),
      switchMap(() =>
        this.communicationsService.getAllowedWhatsAppFileTypes().pipe(
          map((response: AllowedWhatsAppFileTypes) =>
            communicationsActions.getWhatsAppAllowedFileTypesSuccess({
              response,
            }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not load allowed file types - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.getWhatsAppAllowedFileTypesFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
  getTemplatedMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.getTemplatedMessage),
      switchMap(({ template, contactId }) =>
        this.communicationsService
          .getTemplatedMessage(template, contactId)
          .pipe(
            map((response: TemplatedMessage) =>
              communicationsActions.getTemplatedMessageSuccess({
                response,
              }),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not load templated message - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.getTemplatedMessageFailure({
                  error: friendlyMessage,
                }),
              );
            }),
          ),
      ),
    ),
  );
  sendTemplatedMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.sendTemplateMessage),
      switchMap(({ conversationId, templateId }) =>
        this.communicationsService
          .sendWhatsAppMessage({
            conversationId,
            templateId,
          })
          .pipe(
            map(() =>
              communicationsActions.sendTemplateMessageSuccess({
                conversationId,
              }),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Unable to send templated message - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.sendTemplateMessageFailure({
                  error: friendlyMessage,
                }),
              );
            }),
          ),
      ),
    ),
  );
  reloadConversationAfterSendTemplateMessageSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.sendTemplateMessageSuccess),
      map((action) =>
        communicationsActions.loadConversationDetails({
          conversationId: action.conversationId,
        }),
      ),
    ),
  );
  reloadMessagesAfterSendTemplateMessageSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.sendTemplateMessageSuccess),
      map((action) =>
        communicationsActions.loadLatestMessagesForConversation({
          conversationId: action.conversationId,
        }),
      ),
    ),
  );
  loadSystemContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadSystemContactDetails),
      switchMap(() =>
        this.communicationsService.getSystemContactDetails().pipe(
          map((contactDetails: SystemContact[]) =>
            communicationsActions.loadSystemContactDetailsSuccess({
              response: contactDetails,
            }),
          ),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not load system contact details - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.loadSystemContactDetailsFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadPensionsBeneficiaryContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadPensionsBeneficiaryContactDetails),
      switchMap(({ claimId }) =>
        this.communicationsService
          .getPensionsBeneficiaryContactDetails(claimId)
          .pipe(
            map((contactDetails: PensionsBeneficiaryContactDetails[]) =>
              communicationsActions.loadPensionsBeneficiaryContactDetailsDataSuccess(
                {
                  response: contactDetails,
                },
              ),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not load contact details - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.loadPensionsBeneficiaryContactDetailsDataFailure(
                  {
                    error: friendlyMessage,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );
  startPensionsBeneficiaryWhatsAppConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        communicationsActions.startPensionsBeneficiaryWhatsAppConversation,
      ),
      switchMap(({ request }) =>
        this.communicationsService
          .startPensionsBeneficiaryConversation(request)
          .pipe(
            switchMap(
              (response: StartPensionsBeneficiaryConversationResponse) => [
                communicationsActions.startPensionsBeneficiaryWhatsAppConversationSuccess(),
                communicationsActions.sendWhatsAppOptIn({
                  request: {
                    ...request,
                    conversationId: response.conversationId,
                  },
                }),
              ],
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not start WhatsApp conversation - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.startPensionsBeneficiaryWhatsAppConversationFailure(
                  {
                    error: friendlyMessage,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );
  startPensionsBeneficiarySmsConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.startPensionsBeneficiarySmsConversation),
      switchMap(({ request }) =>
        this.communicationsService
          .startPensionsBeneficiaryConversation(request)
          .pipe(
            switchMap(
              (response: StartPensionsBeneficiaryConversationResponse) => [
                communicationsActions.startPensionsBeneficiarySmsConversationSuccess(),
                communicationsActions.sendSms({
                  request: {
                    ...request,
                    conversationId: response.conversationId,
                  },
                }),
              ],
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not start beneficiary conversation - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.startPensionsBeneficiarySmsConversationFailure(
                  {
                    error: friendlyMessage,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );
  loadPensionsBeneficiarySmsMessageTemplatePreview = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadPensionsSmsMessageTemplatePreview),
      switchMap(({ messageTemplate, claimId }) =>
        this.communicationsService
          .getPensionsBeneficiarySmsMessageTemplate(messageTemplate, claimId)
          .pipe(
            map((response: SmsMessageTemplateResponse) =>
              communicationsActions.loadPensionsSmsMessageTemplatePreviewSuccess(
                {
                  template: response.template,
                },
              ),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Unable to get pensions beneficiary sms message template - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.loadPensionsSmsMessageTemplatePreviewFailure(
                  {
                    error: friendlyMessage,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );

  loadSMSMessageHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadSMSMessageHistory),
      switchMap(
        ({
          senders,
          receivers,
          messageType,
          offset,
          limit,
          searchData,
          sort,
          complexSearchData,
        }) =>
          this.communicationsService
            .getMessageHistory(
              messageType,
              senders,
              receivers,
              offset,
              limit,
              sort,
              searchData,
              complexSearchData,
            )
            .pipe(
              map((response) =>
                communicationsActions.loadSMSMessageHistorySuccess({
                  response,
                }),
              ),
              catchError(({ friendlyMessage }: ApiError) => {
                this.toastService.showError(
                  `Unable to get sms message history - ${friendlyMessage}`, // TODO message type
                );
                return of(
                  communicationsActions.loadSMSMessageHistoryFailure({
                    error: friendlyMessage,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(communicationsActions.loadSMSMessageHistory),
                ),
              ),
            ),
      ),
    ),
  );
  loadEducationAssistanceContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.loadEducationAssistanceContactDetails),
      switchMap(({ beneficiaryId }) =>
        this.communicationsService
          .getEducationAssistanceContactDetails(beneficiaryId)
          .pipe(
            map(
              (
                contactDetails: EducationAssistanceBeneficiaryContactDetails[],
              ) =>
                communicationsActions.loadEducationAssistanceContactDetailsSuccess(
                  {
                    response: contactDetails,
                  },
                ),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not load contact details - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.loadEducationAssistanceContactDetailsFailure(
                  {
                    error: friendlyMessage,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );
  startEducationAssistanceWhatsAppConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        communicationsActions.startEducationAssistanceWhatsAppConversation,
      ),
      switchMap(({ request }) =>
        this.communicationsService
          .startEducationAssistanceConversation(request)
          .pipe(
            switchMap(
              (response: StartEducationAssistanceConversationResponse) => [
                communicationsActions.startEducationAssistanceWhatsAppConversationSuccess(),
                communicationsActions.sendWhatsAppOptIn({
                  request: {
                    ...request,
                    conversationId: response.conversationId,
                  },
                }),
              ],
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not start WhatsApp conversation - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.startEducationAssistanceWhatsAppConversationFailure(
                  {
                    error: friendlyMessage,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );

  startCommunicationsNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.startCommunicationsNotifications),
      switchMap(() =>
        this.communicationsService.startNotifications().pipe(
          switchMap(() => [
            communicationsActions.startWhatsAppUnreadNotifications(),
          ]),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `Could not unread start communications notifications - ${friendlyMessage}`,
            );
            return of(
              communicationsActions.startCommunicationsNotificationsFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  startWhatsAppUnreadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationsActions.startWhatsAppUnreadNotifications),
      switchMap(() =>
        this.communicationsService
          .getWhatsAppNotifications(
            (notification: UnreadWhatsappConversationsNotification) =>
              this.store$.dispatch(
                communicationsActions.updateWhatsAppUnreadNotifications({
                  notification,
                }),
              ),
          )
          .pipe(
            map(() =>
              communicationsActions.startWhatsAppUnreadNotificationsSuccess(),
            ),
            catchError(({ friendlyMessage }: ApiError) => {
              this.toastService.showError(
                `Could not unread start whatsapp unread notifications - ${friendlyMessage}`,
              );
              return of(
                communicationsActions.startWhatsAppUnreadNotificationsFailure({
                  error: friendlyMessage,
                }),
              );
            }),
          ),
      ),
    ),
  );
}

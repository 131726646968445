import { Component, OnInit } from '@angular/core';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import { map, takeUntil } from 'rxjs/operators';
import { ClaimWhatsappModalFormService } from '@app/communications/components/claim-whatsapp-modal/services/claim-whatsapp-modal-form.service';
import { SelectOption } from '@app/core/models/selector-option';
import { displayRelationship } from '@app/core/models/personal-relationship';
import { ClaimContactDetails } from '@app/communications/models/claim-contact-details';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendClaimMessageRequest } from '@app/communications/models/send-claim-message-request';
import { Department } from '@app/communications/models/department.enum';
import { combineLatest } from 'rxjs';
import { SystemContactVisibility } from '@app/communications/models/system-contact-visibility.enum';

@Component({
  selector: 'app-claim-whatsapp-modal',
  templateUrl: './claim-whatsapp-modal.component.html',
  styleUrls: ['./claim-whatsapp-modal.component.scss'],
  providers: [ClaimWhatsappModalFormService],
})
export class ClaimWhatsappModalComponent
  extends AutoUnsubscription
  implements OnInit
{
  claimCode: string;
  isStartingConversation$ = this.facade.isStartingConversation$;
  isSendingOptIn$ = this.facade.isSendingOptIn$;
  contactDetails$ = this.facade.claimContactDetails$;
  contactDetailsIsLoading$ = this.facade.claimContactDetailsIsLoading$;
  isLoading$ = combineLatest([
    this.isStartingConversation$,
    this.isSendingOptIn$,
  ]).pipe(
    map(
      ([isStartingConversation, isSendingOptIn]) =>
        isStartingConversation || isSendingOptIn,
    ),
  );
  contactDetailsOptions: SelectOption[] = [];
  senderOptions: SelectOption[] = [];
  contactDetails: ClaimContactDetails[] = [];
  metadata$ = this.facade.userMessagingMetadata$;
  metadata: UserMessagingMetadata;
  form = this.formService.form;
  canSendOptIn = false;
  hasValidContact = false;
  hasValidSystemContact = false;

  conversationId?: number;

  constructor(
    private facade: CommunicationsFacade,
    private formService: ClaimWhatsappModalFormService,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.facade.loadUserMessagingMetadata();
    this.metadata$.pipe(takeUntil(this.notifier)).subscribe((metadata) => {
      if (metadata.data) {
        this.metadata = metadata.data;
        this.senderOptions = metadata.data.contacts
          .filter(
            (contact) =>
              (contact.departmentId === Department.Claims ||
                contact.departmentId === Department.Registry) &&
              contact.isActive &&
              contact.visibilityLevel === SystemContactVisibility.SendMessages,
          )
          .map((contact) => ({
            value: contact.systemContactId,
            description: `${contact.name} ${contact.phoneNumber}`,
          }));
        this.hasValidSystemContact = this.senderOptions.length > 0;
        if (this.hasValidSystemContact) {
          this.formService.patchSender(+this.senderOptions[0].value);
        }
      }
    });

    this.contactDetails$
      .pipe(takeUntil(this.notifier))
      .subscribe((contactDetails) => {
        if (contactDetails) {
          this.contactDetailsOptions = contactDetails
            .filter((contact) => contact.isValidPhoneNumber)
            .map((details) => ({
              value: details.claimsContactId,
              description: `${details.name ?? ''} ${
                details.surname ?? ''
              } (${displayRelationship(details.relationship)}) ${
                details.phoneNumber
              }`,
            }));
          this.contactDetails = contactDetails;
          this.hasValidContact = this.contactDetailsOptions.length > 0;
          if (this.hasValidContact) {
            this.formService.patchClaimContact(
              +this.contactDetailsOptions[0].value,
            );
          }
        }
      });

    this.form.valueChanges
      .pipe(takeUntil(this.notifier))
      .subscribe((formValue) => {
        const contactId = formValue.claimContact;
        const systemContactId = formValue.sender;
        const contact = this.contactDetails.find(
          (contact) => contact.claimsContactId === contactId,
        );
        const systemContact = this.metadata.contacts?.find(
          (contact) => contact.systemContactId === systemContactId,
        );
        const conversation = contact?.conversations.find(
          (conversation) =>
            (conversation.systemContactId === systemContactId ||
              conversation.systemContactPhoneNumber ==
                systemContact.phoneNumber) &&
            conversation.hasWhatsAppMessages,
        );
        this.canSendOptIn = !conversation;
        this.conversationId = conversation?.conversationId;
      });
  }

  sendOptIn() {
    if (this.form.valid) {
      const request: SendClaimMessageRequest =
        this.formService.populateRequestModelFromForm(this.claimCode);
      this.facade.startClaimConversation(request);
    }
  }

  goToConversation() {
    if (this.form.valid && this.conversationId) {
      window.open(
        `/communications/whatsapp-chat/conversations/${this.conversationId}`,
        '_blank',
      );
      this.modalService.dismissAll();
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-conversations-list-item',
  templateUrl: './whatsapp-conversation-list-item.component.html',
  styleUrls: ['./whatsapp-conversation-list-item.component.scss'],
})
export class WhatsAppConversationsListItemComponent implements OnInit {
  @Input()
  conversationId: number;

  @Input()
  firstName: string;

  @Input()
  surname: string;

  @Input()
  phoneNumber: string;

  @Input()
  isRead: boolean;

  constructor() {}

  ngOnInit(): void {}
}

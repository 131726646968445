<app-modal [title]="'Send WhatsApp'">
  <app-circle-loader
    [loading]="
      (contactDetailsIsLoading$ | async) ||
      ((metadata$ | async)?.isLoading ?? false)
    ">
    <div class="container">
      <form [formGroup]="form">
        <div class="row">
          <div
            *ngIf="
              !((contactDetailsIsLoading$ | async) ?? false) && !hasValidContact
            "
            class="col-12">
            <app-callout [type]="'danger'">
              <label class="text-danger">
                The claim does not have valid contact details to send a WhatsApp
                message to. Please update the contact details to proceed.
              </label>
            </app-callout>
          </div>
          <div
            *ngIf="
              !((metadata$ | async)?.isLoading ?? false) &&
              !hasValidSystemContact
            "
            class="col-12">
            <app-callout [type]="'danger'">
              <label class="text-danger">
                You do not have the permissions to send messages from a claim.
                Please contact IT if this is an error.
              </label>
            </app-callout>
          </div>
        </div>
        <div class="row" *ngIf="hasValidContact && hasValidSystemContact">
          <div class="col-12">
            <app-select-input
              [appendLabel]="true"
              [inputName]="'claimContact'"
              [labelName]="'Recipient'"
              [parentForm]="form"
              [selectOptions]="contactDetailsOptions">
            </app-select-input>
          </div>
          <div class="col-12">
            <app-select-input
              [appendLabel]="true"
              [inputName]="'sender'"
              [labelName]="'Sender'"
              [parentForm]="form"
              [selectOptions]="senderOptions">
            </app-select-input>
          </div>
          <div class="col-6 mt-2">
            <button
              (click)="sendOptIn()"
              *ngIf="canSendOptIn"
              [disabled]="this.form.invalid || (isLoading$ | async)"
              class="btn btn-primary"
              type="submit">
              <div
                class="spinner-border spinner-border-sm mr-1"
                role="status"
                *ngIf="isLoading$ | async"></div>
              {{
                (isLoading$ | async) ? 'Sending Opt In' : 'Send Opt In Message'
              }}
            </button>
            <button
              (click)="goToConversation()"
              *ngIf="!canSendOptIn"
              [disabled]="this.form.invalid"
              class="btn btn-primary"
              type="submit">
              Go to Chat
            </button>
          </div>
        </div>
      </form>
    </div>
  </app-circle-loader>
</app-modal>

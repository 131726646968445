<app-circle-loader [loading]="isInitialLoad$ | async">
  <ng-container *ngIf="senders$ | async; let senders">
    <ng-container *ngIf="receivers$ | async; let receivers">
      <app-communication-messages-table
        (openSMSSender)="this.showSmsModal()"
        (openWhatsAppSender)="this.openWhatsAppModal()"
        [receivers]="receivers"
        [senders]="senders"
        [showSmsSender]="true"
        [showWhatsAppSender]="true">
      </app-communication-messages-table>
    </ng-container>
  </ng-container>
</app-circle-loader>

<a
  [routerLink]="['conversations', conversationId]"
  [class.unread-bg]="!isRead"
  class="d-flex justify-content-between list-item flex-column p-3"
  routerLinkActive="active">
  <div class="d-flex flex-row">
    <div class="rounded-circle d-flex align-self-center avatar me-3">
      <span class="fem-icon fem-icon-user-circle"></span>
    </div>
    <div class="align-self-center flex-grow-1">
      <div class="d-flex flex-row">
        <div class="title flex-grow-1">
          <app-contact-display-name
            [firstName]="firstName"
            [phoneNumber]="phoneNumber"
            [surname]="surname"></app-contact-display-name>
        </div>
        @if (!isRead) {
          <div class="rounded-circle align-self-center unread"></div>
        }
      </div>
      @if (firstName || surname) {
        <div class="small sub-title mt-1">{{ phoneNumber }}</div>
      }
    </div>
  </div>
</a>

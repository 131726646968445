import { Injectable } from '@angular/core';
import { FormService } from '@app/shared/services/form.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MessageForm } from '@app/communications/components/whatsapp-message-input/message-input';

@Injectable()
export class MessageFormService implements FormService<MessageForm> {
  messageControl = new UntypedFormControl(null);
  attachmentControl = new UntypedFormControl(null);
  messageOrAttachmentSet =
    (): ValidatorFn =>
    (group: UntypedFormGroup): { [key: string]: boolean } => {
      const messageSet =
        group.controls.message.value !== null &&
        group.controls.message.value !== '';
      const attachmentSet = group.controls.attachment.value !== null;

      if (messageSet || attachmentSet) {
        return null;
      } else {
        return {
          messageOrAttachmentSet: true,
        };
      }
    };

  form = new UntypedFormGroup(
    {
      message: this.messageControl,
      attachment: this.attachmentControl,
    },
    this.messageOrAttachmentSet(),
  );

  setMessageMaxLength(messageMaxLength: number) {
    this.messageControl.addValidators(Validators.maxLength(messageMaxLength));
  }

  getValue(): MessageForm {
    return {
      message: this.messageControl.value,
      attachment: this.attachmentControl.value,
    };
  }

  populateForm(data: MessageForm): void {}

  resetForm(): void {
    this.form.reset();
  }

  disableForm(): void {
    this.form.disable();
  }

  enableForm(): void {
    this.form.enable();
  }

  setMessage(message: string) {
    this.messageControl.setValue(message);
  }

  resetMessage() {
    this.messageControl.reset();
  }
}

import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class WhatsAppConversationListFormService {
  form = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });

  resetForm() {
    this.form.patchValue({ search: '' });
  }
}

<div class="message-input-row d-flex flex-column" [ngbTooltip]="tooltip">
  <div *ngIf="attachmentControl.value as attachment" class="attachment-row p-2">
    <div class="attachment-tile rounded shadow d-flex flex-column">
      <div class="d-flex">
        <span class="fem-icon fem-icon-draft file-icon flex-grow-1"></span>
        <span class="fem-icon fem-icon-delete align-self-start m-1 delete-icon" (click)="removeAttachment()"></span>
      </div>
      <span class="attachment-name p-2">{{ attachment.name }}</span>
    </div>
  </div>
  <div class="message-input-row d-flex flex-row p-2 align-items-end">
    <button (click)="openFileDialog()"
            [disabled]="disabled || attachmentControl.value"
            class="btn btn-sm icon-button"><span
      class="fem-icon fem-icon-attach-file"></span>
    </button>
    <input
      #fileInput
      (onFileSelected)="setSelectedFile($event)"
      [multiple]="false"
      [uploader]="uploader"
      class="d-none mb-3"
      ng2FileSelect
      type="file"
      [formControl]="fileControl"
      [accept]="allowedFileTypesList"
    />
    <textarea
      [formControl]="messageControl"
      [maxlength]="messageMaxLength"
      [rows]="3"
      class="form-control me-1 ms-1 message-box"
    ></textarea>

    <button (click)="sendClicked()"
            [disabled]="disabled || formGroup.invalid"
            class="btn btn-sm icon-button">
      <div>
        <div *ngIf="sendingMessage" class="spinner-border spinner text-primary">
        </div>
      </div>
      <span *ngIf="!sendingMessage" class="fem-icon fem-icon-send"></span>
    </button>
  </div>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunicationsState } from './communications.state';
import { communicationsFeatureKey } from './communications.reducer';

export const getCommunicationsFeatureState =
  createFeatureSelector<CommunicationsState>(communicationsFeatureKey);
export const getUserMessageHistoryState = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.myConversations,
);

export const getUserMessageHistoryIsLoading = createSelector(
  getUserMessageHistoryState,
  (state) => state.isLoading,
);
export const getUserMessagingMetadataState = createSelector(
  getCommunicationsFeatureState,
  (state) => state.userMessagingMetadata,
);

export const getUserMessagingMetadataIsLoading = createSelector(
  getUserMessagingMetadataState,
  (state) => state.isLoading ?? false,
);

export const getConversationDetails = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.selectedConversationDetails.data,
);

export const getConversationDetailsIsLoading = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.selectedConversationDetails.isLoading,
);

export const getMessageHistoryState = createSelector(
  getCommunicationsFeatureState,
  (state) => state.messageHistory,
);

export const getMessageHistoryIsLoading = createSelector(
  getMessageHistoryState,
  (state) => state.isLoading,
);

export const getWhatsappConversationMessages = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.conversationMessages.data,
);

export const getWhatsappConversationMessagesIsLoading = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.conversationMessages.isLoading,
);
export const getClaimContacts = createSelector(
  getCommunicationsFeatureState,
  (state) => state.claimContacts.data,
);
export const getClaimContactsIsLoading = createSelector(
  getCommunicationsFeatureState,
  (state) => state.claimContacts.isLoading ?? false,
);
export const getIsStartingConversation = createSelector(
  getCommunicationsFeatureState,
  (state) => state.isStartingConversation,
);
export const getIsSendingOptIn = createSelector(
  getCommunicationsFeatureState,
  (state) => state.isSendingOptIn,
);
export const getWhatsappIsBeingSent = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.sendMessage.isLoading,
);

export const getSelectedClaimSmsTemplatePreview = createSelector(
  getCommunicationsFeatureState,
  (state) => state.sendClaimSmsModal.selectedSmsTemplatePreview,
);

export const getAllowedWhatsAppFileTypes = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.allowedFileTypes.data,
);

export const getTemplatedMessage = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.templatedMessage.data,
);

export const getTemplateMessageIsSending = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsApp.sendTemplateMessage.isLoading,
);

export const getSystemContacts = createSelector(
  getCommunicationsFeatureState,
  (state) => state.systemContacts.data,
);
export const getSystemContactsIsLoading = createSelector(
  getCommunicationsFeatureState,
  (state) => state.systemContacts.isLoading ?? false,
);
export const getPensionsBeneficiaryContacts = createSelector(
  getCommunicationsFeatureState,
  (state) => state.pensionsBeneficiaryContacts.data,
);
export const getPensionsBeneficiaryContactsIsLoading = createSelector(
  getCommunicationsFeatureState,
  (state) => state.pensionsBeneficiaryContacts.isLoading ?? false,
);

export const getSelectedPensionsBeneficiarySmsTemplatePreview = createSelector(
  getCommunicationsFeatureState,
  (state) => state.sendPensionsBeneficiarySmsModal.selectedSmsTemplatePreview,
);
export const getIsSendingSms = createSelector(
  getCommunicationsFeatureState,
  (state) => state.isSendingSms,
);

export const getSMSMessageHistoryState = createSelector(
  getCommunicationsFeatureState,
  (state) => state.smsMessageHistory,
);

export const getSMSMessageHistoryIsLoading = createSelector(
  getSMSMessageHistoryState,
  (state) => state.isLoading,
);
export const getEducationAssistanceContacts = createSelector(
  getCommunicationsFeatureState,
  (state) => state.educationAssistanceBeneficiaryContacts.data,
);

export const getEducationAssistanceContactsIsLoading = createSelector(
  getCommunicationsFeatureState,
  (state) => state.educationAssistanceBeneficiaryContacts.isLoading,
);
export const getIsCreatingUnreadWhatsappConversationNotifications =
  createSelector(
    getCommunicationsFeatureState,
    (state) => state.isCreatingUnreadWhatsappConversationNotifications,
  );

export const getWhatsappConversationNotifications = createSelector(
  getCommunicationsFeatureState,
  (state) => state.whatsAppUnreadConversations,
);

import { TableDefinition } from '@app/shared/dashboard-table/table-definitions/table-definition';
import { Injectable } from '@angular/core';
import { SortPropDir } from '@swimlane/ngx-datatable';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import { MessageHistory } from '@app/communications/models/message-history';
import { mapPipe } from '@app/shared/pipes/onceOff/mapPipe';
import { Router } from '@angular/router';
import { StandardDateTimePipe } from '@app/shared/pipes/standard-date-time.pipe';

@Injectable()
export class WhatsAppMessageTableDefinition extends TableDefinition<MessageHistory> {
  public senders: string[] = [];
  public receivers: string[] = [];
  readonly messageTypes: MessageTypeEnum[] = [MessageTypeEnum.Whatsapp];
  isSelected = false;

  constructor(
    private facade: CommunicationsFacade,
    private configService: AppConfigService,
    private router: Router,
    private dateTimePipe: StandardDateTimePipe,
  ) {
    super();
    this.title = 'WhatsApp';
    this.showTableTotal = false;
    this.columns = [
      {
        name: 'Date',
        prop: 'sentDate',
        maxWidth: 200,
        pipe: this.dateTimePipe,
      },
      {
        name: 'Message Type',
        prop: 'isOutboundMessage',
        maxWidth: 175,
        pipe: mapPipe((isOutbound) => (isOutbound ? 'Outbound' : 'Inbound')),
      },
      { name: 'Sender', prop: 'sender', maxWidth: 150 },
      { name: 'Recipient', prop: 'recipient', maxWidth: 150 },
      { name: 'Message Preview', prop: 'messageContent', minWidth: 150 },
    ];
    this.rows$ = this.facade.messageHistory$;
    this.bindActions();
    this.isSelected = true;
  }

  loadInitialData(): void {
    this.facade.resetMessageHistory(this.senders, this.receivers, [
      MessageTypeEnum.Whatsapp,
    ]);
  }

  bindActions = () => {};

  registerSubscriptions(): void {
    this.subscriptions.push(
      this.facade.messageHistoryIsLoading$.subscribe((loading) => {
        this.loadData(loading);
      }),
    );
  }

  get isVisible() {
    return this.configService.config.features.communications;
  }

  sort(sort: SortPropDir): void {
    this.facade.sortMessageHistory(
      this.senders,
      this.receivers,
      this.messageTypes,
      sort,
    );
  }

  loadMore({ offset, limit }: { offset: number; limit: number }): void {
    this.facade.loadMoreMessageHistory(
      this.senders,
      this.receivers,
      this.messageTypes,
      offset,
      limit,
    );
  }

  select(item: MessageHistory) {
    window.open(
      `/communications/whatsapp-chat/conversations/${item.conversationId}`,
      '_blank',
    );
  }

  updateReceivers(receivers: string[]) {
    this.receivers = receivers;
    if (this.isSelected) {
      this.loadInitialData();
    }
  }
}

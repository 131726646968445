<div class="chat-header p-2 shadow">
  <div class="flex-grow-1 d-flex flex-row flex-wrap align-items-center">
    <div class="rounded-circle d-flex align-self-center avatar me-3">
      <span class="fem-icon fem-icon-user-circle"></span>
    </div>
    <div class="align-self-center">
      <div class="title">
        <app-contact-display-name
          [firstName]="contactFirstName"
          [phoneNumber]="contactNumber"
          [surname]="contactSurname">
        </app-contact-display-name>
      </div>
      <div *ngIf="contactFirstName || contactSurname" class="small sub-title mt-1">{{contactNumber}}</div>
    </div>
  </div>
  <div class="flex-grow-0">
    <app-text-display [applyFormStyling]="false" [value]="systemContactName" labelName="Sent From"></app-text-display>
  </div>
</div>

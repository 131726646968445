import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';
import { PensionsBeneficiaryWhatsappForm } from '@app/communications/models/pensions-beneficiary-whatsapp-form';
import { SendPensionsBeneficiaryMessageRequest } from '@app/communications/models/send-pensions-beneficiary-message-request';

@Injectable()
export class PensionsWhatsappModalFormService {
  form: FormGroup<PensionsBeneficiaryWhatsappForm>;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group<PensionsBeneficiaryWhatsappForm>({
      pensionsBeneficiaryContactId: new FormControl<number>(0, {
        validators: Validators.required,
        nonNullable: true,
      }),
      systemContactId: new FormControl<number>(0, {
        validators: Validators.required,
        nonNullable: true,
      }),
    });
  }

  populateRequestModelFromForm(
    beneficiaryId: number,
  ): SendPensionsBeneficiaryMessageRequest {
    const formValues = this.form.getRawValue();
    return {
      ...formValues,
      beneficiaryId,
      messageType: MessageTypeEnum.Whatsapp,
    };
  }

  patchPensionsBeneficiaryContact(value: number) {
    this.form.controls.pensionsBeneficiaryContactId.patchValue(value);
  }

  patchSender(value: number) {
    this.form.controls.systemContactId.patchValue(value);
  }
}

<div class="whatsapp-container">
  <div class="chat-container">
    <div class="d-flex flex-row whatsapp-heading-container align-items-center p-3">
      <span class="whatsapp-heading me-3">WhatsApp</span>
      <span class="fem-icon fem-icon-whatsapp whatsapp-icon"></span>
    </div>
    <div class="d-flex flex-row justify-content-center">
      <ng-container *ngIf="(conversationsAreLoading$ | async) === false; else loader">
        <app-whatsapp-conversations-list
          (searchEmitter)="searchConversations($event)"
          [conversations]="myWhatsAppMessageHistory$ | async">
        </app-whatsapp-conversations-list>
        <div class="flex-grow-1 details-container">
          <app-empty-whatsapp-chat *ngIf="showEmptyState"></app-empty-whatsapp-chat>
          <router-outlet
            (activate)="onActivate()"
            (deactivate)="onDeactivate()">
          </router-outlet>
        </div>
      </ng-container>
      <ng-template #loader>
        <app-circle-loader [fullHeight]="true"
                           [loading]="true"></app-circle-loader>
      </ng-template>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageFormService } from '@app/communications/services/message-form.service';

@Component({
  selector: 'app-empty-whatsapp-chat',
  templateUrl: './empty-whatsapp-chat.component.html',
  styleUrls: ['./empty-whatsapp-chat.component.scss'],
  providers: [MessageFormService],
})
export class EmptyWhatsappChatComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }
}

import { initialPagedData, PagedApiData } from '@app/core/models/PagedApiData';
import { WhatsappMessageHistory } from '@app/communications/models/whatsapp-message-history';
import { ApiData, createApiDataInitialState } from '@app/core/models/api-data';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import { MessageHistory } from '@app/communications/models/message-history';
import { WhatsappConversationDetails } from '@app/communications/models/whatsapp-conversation-details';
import { Message } from '@app/communications/models/message';
import { SendWhatsAppMessageRequest } from '@app/communications/models/send-whatsapp-message-request';
import { ClaimContactDetails } from '@app/communications/models/claim-contact-details';
import { AllowedWhatsAppFileTypes } from '@app/communications/models/allowed-whatsapp-file-types';
import { TemplatedMessage } from '@app/communications/models/templated-message';
import { SystemContact } from '@app/communications/models/system-contact';
import { PensionsBeneficiaryContactDetails } from '@app/communications/models/pensions-beneficiary-contact-details';
import { EducationAssistanceBeneficiaryContactDetails } from '@app/communications/models/education-assistance-beneficiary-contact-details';
import {
  defaultNotification,
  UnreadWhatsappConversationsNotification,
} from '@app/communications/models/unread-whatsapp-conversations.notification';

export interface CommunicationsState {
  claimContacts: ApiData<ClaimContactDetails[]>;
  isSendingOptIn: boolean;
  isStartingConversation: boolean;
  messageHistory: PagedApiData<MessageHistory>;
  smsMessageHistory: PagedApiData<MessageHistory>;
  pensionsBeneficiaryContacts: ApiData<PensionsBeneficiaryContactDetails[]>;
  sendClaimSmsModal: {
    selectedSmsTemplatePreview: string;
  };
  sendPensionsBeneficiarySmsModal: {
    selectedSmsTemplatePreview: string;
  };
  systemContacts: ApiData<SystemContact[]>;
  userMessagingMetadata: ApiData<UserMessagingMetadata>;
  whatsApp: {
    selectedConversationDetails: ApiData<WhatsappConversationDetails>;
    myConversations: PagedApiData<WhatsappMessageHistory>;
    conversationMessages: PagedApiData<Message>;
    sendMessage: ApiData<SendWhatsAppMessageRequest>;
    allowedFileTypes: ApiData<AllowedWhatsAppFileTypes>;
    templatedMessage: ApiData<TemplatedMessage>;
    sendTemplateMessage: ApiData<SendWhatsAppMessageRequest>;
  };
  isSendingSms: boolean;
  educationAssistanceBeneficiaryContacts: ApiData<
    EducationAssistanceBeneficiaryContactDetails[]
  >;
  whatsAppUnreadConversations: UnreadWhatsappConversationsNotification;
  isCreatingUnreadWhatsappConversationNotifications: boolean;
}

export const initialCommunicationsState: CommunicationsState = {
  messageHistory: initialPagedData,
  smsMessageHistory: initialPagedData,
  userMessagingMetadata: createApiDataInitialState(),
  whatsApp: {
    myConversations: initialPagedData,
    selectedConversationDetails: createApiDataInitialState(),
    conversationMessages: initialPagedData,
    sendMessage: createApiDataInitialState(),
    allowedFileTypes: createApiDataInitialState(),
    templatedMessage: createApiDataInitialState(),
    sendTemplateMessage: createApiDataInitialState(),
  },
  sendClaimSmsModal: {
    selectedSmsTemplatePreview: '',
  },
  claimContacts: createApiDataInitialState(),
  isStartingConversation: false,
  isSendingOptIn: false,
  systemContacts: createApiDataInitialState(),
  pensionsBeneficiaryContacts: createApiDataInitialState(),
  sendPensionsBeneficiarySmsModal: {
    selectedSmsTemplatePreview: '',
  },
  isSendingSms: false,
  educationAssistanceBeneficiaryContacts: createApiDataInitialState(),
  whatsAppUnreadConversations: defaultNotification,
  isCreatingUnreadWhatsappConversationNotifications: false,
};

<div class="empty-chat row" >
  <div class="col-12 wrapper">
    <div class="empty-chat-popup row">
      <div class="col-12 row">
        <app-icon [name]="'error'" class="col-1 icon"></app-icon>
        <div class="col-11 description">
          Select a contact to continue a conversation. If you don't have any existing chats, select a claim to initiate a conversation.
        </div>
      </div>
      <div class="col-12 button-div">
        <button (click)="goToDashboard()" class="btn btn-primary">Go To Dashboard</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, pairwise, take, takeUntil } from 'rxjs/operators';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import {
  defaultOffset,
  maxLimit,
} from '@app/shared/helpers/pagination-helpers';
import { SendWhatsAppMessageRequest } from '@app/communications/models/send-whatsapp-message-request';
import { MessageFormService } from '@app/communications/services/message-form.service';
import { ConversationStatus } from '@app/communications/models/conversation-status.enum';
import {
  mapDepartmentToResumptionTemplate,
  WhatsappTemplate,
} from '@app/communications/models/whatsapp-template.enum';
import { animate, style, transition, trigger } from '@angular/animations';
import { WhatsappConversationDetails } from '@app/communications/models/whatsapp-conversation-details';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-whatsapp-conversation',
  templateUrl: './whatsapp-conversation.component.html',
  styleUrls: ['./whatsapp-conversation.component.scss'],
  providers: [MessageFormService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class WhatsappConversationComponent
  extends AutoUnsubscription
  implements OnInit
{
  conversationDetails$ = this.commsFacade.conversationDetails$;
  conversationMessages$ = this.commsFacade.whatsappConversationMessages$;
  conversationId$ = this.activatedRoute.params.pipe(map((params) => params.id));
  whatsappMessageSending$ = this.commsFacade.whatsAppIsBeingSent$;
  whatsAppSentSuccessfully$ = this.commsFacade.whatsAppSentSuccessfully$;
  allowedFileTypes$ = this.commsFacade.allowedWhatsAppFileTypes$;
  templateMessage$ = this.commsFacade.templatedMessage$;
  templateMessageIsSending$ = this.commsFacade.templateMessageIsSending$;
  conversationStatusEnum = ConversationStatus;
  conversationMessagesIsLoading =
    this.commsFacade.whatsappConversationMessagesAreLoading$.pipe(
      pairwise(),
      filter(([prev, current]) => prev === true && current === false),
      map(([prev, current]) => current),
      take(1),
    );
  conversationDetailsIsLoading$ =
    this.commsFacade.conversationDetailsIsLoading$.pipe(
      pairwise(),
      filter(([prev, current]) => prev === true && current === false),
      map(([prev, current]) => current),
      take(1),
    );
  whatsappTemplate: WhatsappTemplate;

  isLoading$ = combineLatest([
    this.conversationMessagesIsLoading,
    this.conversationDetailsIsLoading$,
  ]).pipe(map(([messages, details]) => messages || details));

  constructor(
    private activatedRoute: ActivatedRoute,
    private commsFacade: CommunicationsFacade,
    private messageFormService: MessageFormService,
  ) {
    super();
    this.conversationId$.pipe(takeUntil(this.notifier)).subscribe((id) => {
      this.commsFacade.loadConversationDetails(Number(id));
      this.commsFacade.resetConversationMessages();
      this.commsFacade.loadWhatsappConversationMessages(
        Number(id),
        defaultOffset,
        maxLimit,
      );
      this.messageFormService.resetForm();
      this.commsFacade.resetTemplatedMessage();
    });
    this.commsFacade.getAllowedWhatsAppFileTypes();

    this.whatsAppSentSuccessfully$
      .pipe(takeUntil(this.notifier))
      .subscribe(() => {
        this.messageFormService.resetForm();
      });

    this.whatsappMessageSending$
      .pipe(takeUntil(this.notifier))
      .subscribe((sending) => {
        if (sending) {
          this.messageFormService.disableForm();
        } else {
          this.messageFormService.enableForm();
        }
      });

    this.conversationDetails$
      .pipe(takeUntil(this.notifier))
      .subscribe((conversation) => {
        if (
          conversation &&
          (conversation.status !== ConversationStatus.Active ||
            conversation.isExpired)
        ) {
          this.messageFormService.disableForm();
        } else {
          this.messageFormService.enableForm();
        }
        if (
          conversation &&
          conversation.status === ConversationStatus.Active &&
          conversation.isExpired
        ) {
          this.whatsappTemplate = mapDepartmentToResumptionTemplate(
            conversation.systemContact.department,
          );
          this.commsFacade.getTemplatedMessage(
            this.whatsappTemplate,
            conversation.contact.id,
          );
        } else {
          this.commsFacade.resetTemplatedMessage();
        }
      });
  }

  ngOnInit(): void {}

  sendMessage() {
    this.conversationId$.pipe(take(1)).subscribe((id) => {
      const formValue = this.messageFormService.getValue();

      const request: SendWhatsAppMessageRequest = {
        conversationId: id,
        message: formValue.message,
        attachment: formValue.attachment
          ? {
              fileType: formValue.attachment.fileType,
              imageBytes: formValue.attachment.imageBytes,
            }
          : null,
      };

      this.commsFacade.sendWhatsappMessage(request);
    });
  }

  handleDownloadMedia(id: number) {
    this.commsFacade.downloadMediaFile(id);
  }

  sendResumptionMessageTemplate() {
    this.commsFacade.sendTemplatedMessage(
      this.activatedRoute.snapshot.params.id,
      this.whatsappTemplate,
    );
  }

  getTooltip(conversation: WhatsappConversationDetails) {
    switch (conversation.status) {
      case ConversationStatus.Paused:
        return 'Awaiting response from the recipient';
      case ConversationStatus.Stopped:
        return 'The recipient has opted out of the conversation';
      case ConversationStatus.PendingOptIn:
      case ConversationStatus.Created:
        return 'The recipient has not opted in to the conversation yet';
      default:
        return '';
    }
  }
}

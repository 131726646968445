import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-display-name',
  templateUrl: './contact-display-name.component.html',
  styleUrls: ['./contact-display-name.component.scss'],
})
export class ContactDisplayNameComponent implements OnInit {
  @Input()
  firstName: string;

  @Input()
  surname: string;

  @Input()
  phoneNumber: string;

  constructor() {}

  ngOnInit(): void {}
}

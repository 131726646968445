<div class="conversation-container d-flex flex-column justify-content-center">
  <ng-container *ngIf="(isLoading$ | async) === false; else loader">
    <ng-container *ngIf="conversationDetails$ | async as conversation">
      <app-whatsapp-chat-heading [contactFirstName]="conversation.contact.name"
                                 [contactNumber]="conversation.contact.phoneNumber"
                                 [contactSurname]="conversation.contact.surname"
                                 [systemContactName]="conversation.systemContact.name"></app-whatsapp-chat-heading>

      <app-whatsapp-messages (downloadMedia)="handleDownloadMedia($event)" [messages]="conversationMessages$ | async">
        <div
          *ngIf="conversation.status === conversationStatusEnum.Active && (templateMessage$ | async) as templateMessage"
          [@fadeInOut]
          class="d-flex justify-content-center p-2"
          template-message>
          <div class="template-message-card d-flex flex-column p-3 shadow rounded">
            <div class="d-flex">
              <span class="fem-icon fem-icon-error icon me-2"></span>
              <div class="flex-grow description">
                <p>WhatsApp does not allow sending messages to a user 24 hours after you last messaged them. You can
                  however send the following template message:</p>
                <p class="template-message-content">{{ templateMessage.messageContent }}</p>
              </div>
            </div>
            <div class="pt-2 d-flex justify-content-center">
              <button (click)="sendResumptionMessageTemplate()"
                      [disabled]="templateMessageIsSending$ | async"
                      class="btn btn-primary">{{(templateMessageIsSending$ | async) === true ? 'Sending...' : 'Send Template Message'}}
              </button>
            </div>
          </div>
        </div>
      </app-whatsapp-messages>
      <app-whatsapp-message-input (sendMessage)="sendMessage()"
                                  [allowedFileTypes]="allowedFileTypes$ | async"
                                  [disabled]="(whatsappMessageSending$ | async) || conversation.status !== conversationStatusEnum.Active || conversation.isExpired"
                                  [sendingMessage]="whatsappMessageSending$ | async"
                                  [tooltip]="getTooltip(conversation)">
      </app-whatsapp-message-input>
    </ng-container>
  </ng-container>
  <ng-template #loader>
    <app-circle-loader [loading]="true"></app-circle-loader>
  </ng-template>
</div>

<ng-container *ngIf="firstName || surname; else number">
  <ng-container *ngIf="firstName && surname;else firstNameOrSurname">
    <span>{{firstName}} {{surname}}</span>
  </ng-container>
  <ng-template #firstNameOrSurname>
    <span>{{ firstName ?? surname }}</span>
  </ng-template>
</ng-container>
<ng-template #number>
  <span>{{phoneNumber}}</span>
</ng-template>

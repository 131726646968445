import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
} from '@microsoft/signalr';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { MsalService } from '@azure/msal-angular';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  protected constructor(
    private appConfig: AppConfigService,
    private msalService: MsalService,
  ) {}

  createConnection(url: string): HubConnection {
    const request = {
      scopes:
        this.appConfig.config.msal.angularConfig.protectedResourceMap
          .bokamosoApi.consentScopes,
    };

    const options: IHttpConnectionOptions = {
      accessTokenFactory: () =>
        lastValueFrom(
          this.msalService
            .acquireTokenSilent(request)
            .pipe(map((token) => token.accessToken)),
        ),
      withCredentials: true,
    };
    return new HubConnectionBuilder()
      .withUrl(this.appConfig.config.serverUrl + url, options)
      .withAutomaticReconnect()
      .build();
  }
}

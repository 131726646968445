import { Component, Input, OnDestroy } from '@angular/core';
import { AllowedWhatsAppFileTypes } from '@app/communications/models/allowed-whatsapp-file-types';
import { Message } from '@app/communications/models/message';
import { CommunicationsService } from '@app/communications/services/communications.service';
import { ToastService } from '@app/core/services/toast.service';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrl: './media-player.component.scss',
})
export class MediaPlayerComponent implements OnDestroy {
  @Input()
  message: Message;

  isVisible = false;
  isDownloaded = false;

  @Input()
  mediaTypes: AllowedWhatsAppFileTypes;

  maxMediaFileSize = 20000000; // 20mb

  private mediaFile$: Observable<string>;
  private mediaFileObjectUrl: string;

  constructor(
    private communicationsHttpService: CommunicationsService,
    private toastService: ToastService,
  ) {}

  ngOnDestroy(): void {
    if (this.mediaFileObjectUrl) {
      URL.revokeObjectURL(this.mediaFileObjectUrl);
    }
  }

  showPlayer() {
    this.isVisible = true;
    this.downloadAudioFile(this.message).subscribe(() => {
      this.isDownloaded = true;
    });
  }

  downloadAudioFile(message: Message): Observable<string> {
    if (!this.mediaFile$) {
      this.mediaFile$ = this.communicationsHttpService
        .downloadMediaFile(message.id)
        .pipe(
          shareReplay(),
          map((blobResponse) => {
            const isMediaLargerThanMaxSize =
              Number(blobResponse.headers.get('content-length')) >
              this.maxMediaFileSize;
            if (isMediaLargerThanMaxSize) {
              throw new Error(
                `The media file is larger than ${this.maxMediaFileSize / 1000000}Mb and cannot be loaded.`,
              );
            }

            if (message.isAudioMedia) {
              const mediaType = blobResponse.headers.get('content-type');
              if (
                !this.mediaTypes.allowedAudioTypes.find((t) => t === mediaType)
              ) {
                throw new Error(
                  `Media type ${mediaType} is not allowed for audio messages`,
                );
              }
            }

            if (!this.mediaFileObjectUrl) {
              this.mediaFileObjectUrl = URL.createObjectURL(blobResponse.body);
            }

            return this.mediaFileObjectUrl;
          }),
          catchError((_) => {
            this.toastService.showError(
              'An error occurred when loading a media file, and it may not be available.',
            );
            return of();
          }),
        );
    }

    return this.mediaFile$!;
  }
}

import { createReducer, on } from '@ngrx/store';
import { initialPagedData } from '@app/core/models/PagedApiData';
import { Message } from '@app/communications/models/message';
import { initialCommunicationsState } from './communications.state';
import * as communicationsActions from './communications.actions';

export const communicationsFeatureKey = 'communications';

const updateMessages = (
  currentMessages: Message[],
  updatedMessages: Message[],
) =>
  currentMessages.map((existingMessage) => {
    const updatedMessage = updatedMessages.find(
      (newMessage) => existingMessage.id === newMessage.id,
    );

    if (updatedMessage) {
      return updatedMessage;
    }

    return existingMessage;
  });

const getNewMessages = (
  currentMessages: Message[],
  updatedMessages: Message[],
) => {
  const maxId = currentMessages[0]?.id ?? 0;
  return updatedMessages.filter((m) => m.id > maxId);
};

export const reducer = createReducer(
  initialCommunicationsState,
  on(communicationsActions.loadUserMessagingMetadata, (state) => ({
    ...state,
    myMessagingMetadata: {
      ...state.userMessagingMetadata,
      data: null,
      isLoading: true,
    },
  })),
  on(
    communicationsActions.loadUserMessagingMetadataSuccess,
    (state, { response }) => ({
      ...state,
      userMessagingMetadata: {
        ...state.userMessagingMetadata,
        data: response,
        isLoading: false,
      },
    }),
  ),
  on(
    communicationsActions.loadUserMessagingMetadataFailure,
    (state, { error }) => ({
      ...state,
      userMessagingMetadata: {
        ...state.userMessagingMetadata,
        data: null,
        isLoading: false,
        error,
      },
    }),
  ),

  on(
    communicationsActions.loadUserMessageHistory,
    (state, { limit, offset, sort, searchData, complexSearchData }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        myConversations: {
          ...state.whatsApp.myConversations,
          limit,
          offset,
          isLoading: true,
          sort,
          searchData,
          complexSearchData,
        },
      },
    }),
  ),
  on(
    communicationsActions.loadUserMessageHistorySuccess,
    (state, { response: { total, totalPages, currentPage, data } }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        myConversations: {
          ...state.whatsApp.myConversations,
          data,
          total,
          totalPages,
          currentPage,
          isLoading: false,
        },
      },
    }),
  ),
  on(
    communicationsActions.loadUserMessageHistoryFailure,
    (state, { error }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        myConversations: {
          ...state.whatsApp.myConversations,
          data: [],
          isLoading: false,
          error,
        },
      },
    }),
  ),
  on(communicationsActions.clearUserMessageHistory, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      myConversations: initialPagedData,
    },
  })),
  on(
    communicationsActions.loadMessageHistory,
    (state, { limit, offset, sort, searchData, complexSearchData }) => ({
      ...state,
      messageHistory: {
        ...state.messageHistory,
        limit,
        offset,
        isLoading: true,
        sort,
        searchData,
        complexSearchData,
      },
    }),
  ),

  on(communicationsActions.loadConversationDetails, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      selectedConversationDetails: {
        ...state.whatsApp.selectedConversationDetails,
        isLoading: true,
      },
    },
  })),
  on(
    communicationsActions.loadConversationDetailsSuccess,
    (state, { conversation }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        selectedConversationDetails: {
          ...state.whatsApp.selectedConversationDetails,
          data: conversation,
          isLoading: false,
        },
      },
    }),
  ),
  on(
    communicationsActions.loadConversationDetailsFailure,
    (state, { error }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        selectedConversationDetails: {
          ...state.whatsApp.selectedConversationDetails,
          data: null,
          isLoading: false,
          error,
        },
      },
    }),
  ),
  on(
    communicationsActions.loadMessageHistory,
    (state, { limit, offset, sort, searchData, complexSearchData }) => ({
      ...state,
      messageHistory: {
        ...state.messageHistory,
        limit,
        offset,
        isLoading: true,
        sort,
        searchData,
        complexSearchData,
      },
    }),
  ),
  on(
    communicationsActions.loadMessageHistorySuccess,
    (state, { response: { total, totalPages, currentPage, data } }) => ({
      ...state,
      messageHistory: {
        ...state.messageHistory,
        data: [...state.messageHistory.data, ...data],
        total,
        totalPages,
        currentPage,
        isLoading: false,
      },
    }),
  ),
  on(communicationsActions.loadMessageHistoryFailure, (state, { error }) => ({
    ...state,
    messageHistory: {
      ...state.messageHistory,
      data: [],
      isLoading: false,
      error,
    },
  })),
  on(communicationsActions.clearMessageHistory, (state) => ({
    ...state,
    messageHistory: {
      ...initialPagedData,
    },
  })),
  on(
    communicationsActions.loadWhatsappConversationMessages,
    (state, { limit, offset }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        conversationMessages: {
          ...state.whatsApp.conversationMessages,
          limit,
          offset,
          isLoading: true,
        },
      },
    }),
  ),
  on(
    communicationsActions.loadWhatsappConversationMessagesSuccess,
    (state, { response: { total, totalPages, currentPage, data } }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        conversationMessages: {
          ...state.whatsApp.conversationMessages,
          data: [...data, ...state.whatsApp.conversationMessages.data],
          total,
          totalPages,
          currentPage,
          isLoading: false,
        },
      },
    }),
  ),
  on(
    communicationsActions.loadWhatsappConversationMessagesFailure,
    (state, { error }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        conversationMessages: {
          ...state.whatsApp.conversationMessages,
          data: [],
          isLoading: false,
          error,
        },
      },
    }),
  ),
  on(communicationsActions.loadClaimSmsMessageTemplatePreview, (state) => ({
    ...state,
    sendClaimSmsModal: {
      ...state.sendClaimSmsModal,
      selectedSmsTemplatePreview: '',
    },
  })),
  on(
    communicationsActions.loadClaimSmsMessageTemplatePreviewSuccess,
    (state, { template }) => ({
      ...state,
      sendClaimSmsModal: {
        ...state.sendClaimSmsModal,
        selectedSmsTemplatePreview: template,
      },
    }),
  ),
  on(
    communicationsActions.loadClaimSmsMessageTemplatePreviewFailure,
    (state) => ({
      ...state,
      sendClaimSmsModal: {
        ...state.sendClaimSmsModal,
        selectedSmsTemplatePreview: '',
      },
    }),
  ),
  on(communicationsActions.clearClaimSmsMessageTemplatePreview, (state) => ({
    ...state,
    sendClaimSmsModal: {
      ...state.sendClaimSmsModal,
      selectedSmsTemplatePreview: '',
    },
  })),
  on(communicationsActions.clearWhatsappConversationMessages, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      conversationMessages: initialPagedData,
    },
  })),
  on(communicationsActions.sendWhatsAppMessage, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      sendMessage: {
        ...state.whatsApp.sendMessage,
        isLoading: true,
      },
    },
  })),
  on(
    communicationsActions.sendWhatsAppMessageSuccess,
    (state, { message }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        sendMessage: {
          ...state.whatsApp.sendMessage,
          isLoading: false,
        },
      },
    }),
  ),
  on(communicationsActions.sendWhatsAppMessageFailure, (state, { error }) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      sendMessage: {
        ...state.whatsApp.sendMessage,
        isLoading: false,
        error,
      },
    },
  })),
  on(
    communicationsActions.markWhatsappConversationAsReadSuccess,
    (state, { conversationId }) => {
      const conversationIndex = state.whatsApp.myConversations.data.findIndex(
        (c) => c.conversationId === conversationId,
      );

      if (conversationIndex < 0) {
        return state;
      }

      const newData = [...state.whatsApp.myConversations.data];
      newData[conversationIndex] = {
        ...state.whatsApp.myConversations.data[conversationIndex],
        isRead: true,
      };

      return {
        ...state,
        whatsApp: {
          ...state.whatsApp,
          myConversations: {
            ...state.whatsApp.myConversations,
            data: newData,
          },
        },
      };
    },
  ),
  on(
    communicationsActions.loadLatestMessagesForConversationSuccess,
    (state, { conversationId, messages }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        conversationMessages: {
          ...state.whatsApp.conversationMessages,
          data: [
            ...getNewMessages(
              state.whatsApp.conversationMessages.data,
              messages,
            ),
            ...updateMessages(
              state.whatsApp.conversationMessages.data,
              messages,
            ),
          ],
        },
      },
    }),
  ),
  on(communicationsActions.loadClaimContactDetails, (state) => ({
    ...state,
    claimContacts: {
      ...state.claimContacts,
      isLoading: true,
    },
  })),
  on(
    communicationsActions.loadClaimContactDetailsDataSuccess,
    (state, { response }) => ({
      ...state,
      claimContacts: {
        ...state.claimContacts,
        data: response,
        isLoading: false,
      },
    }),
  ),
  on(
    communicationsActions.loadClaimContactDetailsDataFailure,
    (state, { error }) => ({
      ...state,
      claimContacts: {
        ...state.claimContacts,
        data: null,
        isLoading: false,
        error,
      },
    }),
  ),
  on(communicationsActions.sendWhatsAppOptIn, (state) => ({
    ...state,
    isSendingOptIn: true,
  })),
  on(
    communicationsActions.sendWhatsAppOptInSuccess,
    communicationsActions.sendWhatsAppOptInFailure,
    (state) => ({
      ...state,
      isSendingOptIn: false,
    }),
  ),
  on(communicationsActions.loadClaimContactDetails, (state) => ({
    ...state,
    claimContacts: {
      ...state.claimContacts,
      data: [],
      isLoading: true,
    },
  })),
  on(
    communicationsActions.loadClaimContactDetailsDataSuccess,
    (state, { response }) => ({
      ...state,
      claimContacts: {
        ...state.claimContacts,
        data: response,
        isLoading: false,
      },
    }),
  ),
  on(
    communicationsActions.loadClaimContactDetailsDataFailure,
    (state, { error }) => ({
      ...state,
      claimContacts: {
        ...state.claimContacts,
        data: null,
        isLoading: false,
        error,
      },
    }),
  ),
  on(
    communicationsActions.startClaimSmsConversation,
    communicationsActions.startClaimWhatsAppConversation,
    communicationsActions.startPensionsBeneficiarySmsConversation,
    communicationsActions.startPensionsBeneficiaryWhatsAppConversation,
    communicationsActions.startEducationAssistanceWhatsAppConversation,
    (state) => ({
      ...state,
      isStartingConversation: true,
    }),
  ),
  on(
    communicationsActions.startClaimSmsConversationSuccess,
    communicationsActions.startClaimSmsConversationFailure,
    communicationsActions.startClaimWhatsAppConversationSuccess,
    communicationsActions.startClaimWhatsAppConversationFailure,
    communicationsActions.startPensionsBeneficiarySmsConversationSuccess,
    communicationsActions.startPensionsBeneficiarySmsConversationFailure,
    communicationsActions.startPensionsBeneficiaryWhatsAppConversationSuccess,
    communicationsActions.startPensionsBeneficiaryWhatsAppConversationFailure,
    communicationsActions.startEducationAssistanceWhatsAppConversationSuccess,
    communicationsActions.startEducationAssistanceWhatsAppConversationFailure,
    (state) => ({
      ...state,
      isStartingConversation: false,
    }),
  ),
  on(communicationsActions.getWhatsAppAllowedFileTypes, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      allowedFileTypes: {
        ...state.whatsApp.allowedFileTypes,
        isLoading: true,
      },
    },
  })),
  on(
    communicationsActions.getWhatsAppAllowedFileTypesSuccess,
    (state, { response }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        allowedFileTypes: {
          ...state.whatsApp.allowedFileTypes,
          data: response,
          isLoading: false,
        },
      },
    }),
  ),
  on(
    communicationsActions.getWhatsAppAllowedFileTypesFailure,
    (state, { error }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        allowedFileTypes: {
          ...state.whatsApp.allowedFileTypes,
          data: null,
          isLoading: false,
          error,
        },
      },
    }),
  ),
  on(communicationsActions.getTemplatedMessage, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      templatedMessage: {
        ...state.whatsApp.templatedMessage,
        isLoading: true,
      },
    },
  })),
  on(
    communicationsActions.getTemplatedMessageSuccess,
    (state, { response }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        templatedMessage: {
          ...state.whatsApp.templatedMessage,
          data: response,
          isLoading: false,
        },
      },
    }),
  ),
  on(communicationsActions.getTemplatedMessageFailure, (state, { error }) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      templatedMessage: {
        ...state.whatsApp.templatedMessage,
        data: null,
        isLoading: false,
        error,
      },
    },
  })),
  on(communicationsActions.resetTemplatedMessage, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      templatedMessage: initialCommunicationsState.whatsApp.templatedMessage,
    },
  })),
  on(communicationsActions.sendTemplateMessage, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      sendTemplateMessage: {
        ...state.whatsApp.sendTemplateMessage,
        isLoading: true,
      },
    },
  })),
  on(communicationsActions.sendTemplateMessageSuccess, (state) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      sendTemplateMessage: {
        ...state.whatsApp.sendTemplateMessage,
        isLoading: false,
      },
    },
  })),
  on(communicationsActions.sendTemplateMessageFailure, (state, { error }) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      sendTemplateMessage: {
        ...state.whatsApp.sendTemplateMessage,
        isLoading: false,
        error,
      },
    },
  })),
  on(communicationsActions.loadSystemContactDetails, (state) => ({
    ...state,
    systemContacts: {
      ...state.systemContacts,
      isLoading: true,
    },
  })),
  on(
    communicationsActions.loadSystemContactDetailsSuccess,
    (state, { response }) => ({
      ...state,
      systemContacts: {
        ...state.systemContacts,
        data: response,
        isLoading: false,
      },
    }),
  ),
  on(
    communicationsActions.loadSystemContactDetailsFailure,
    (state, { error }) => ({
      ...state,
      systemContacts: {
        ...state.systemContacts,
        data: null,
        isLoading: false,
        error,
      },
    }),
  ),
  on(communicationsActions.downloadMediaFile, (state, { id }) => ({
    ...state,
    whatsApp: {
      ...state.whatsApp,
      conversationMessages: {
        ...state.whatsApp.conversationMessages,
        data: state.whatsApp.conversationMessages.data.map((message) => {
          if (message.id === id) {
            return {
              ...message,
              isMediaDownloading: true,
            };
          }
          return message;
        }),
      },
    },
  })),
  on(
    communicationsActions.downloadMediaFileSuccess,
    communicationsActions.downloadMediaFileFailure,
    (state, { id }) => ({
      ...state,
      whatsApp: {
        ...state.whatsApp,
        conversationMessages: {
          ...state.whatsApp.conversationMessages,
          data: state.whatsApp.conversationMessages.data.map((message) => {
            if (message.id === id) {
              return {
                ...message,
                isMediaDownloading: false,
              };
            }
            return message;
          }),
        },
      },
    }),
  ),

  on(communicationsActions.loadPensionsBeneficiaryContactDetails, (state) => ({
    ...state,
    pensionsBeneficiaryContacts: {
      ...state.pensionsBeneficiaryContacts,
      data: [],
      isLoading: true,
    },
  })),
  on(
    communicationsActions.loadPensionsBeneficiaryContactDetailsDataSuccess,
    (state, { response }) => ({
      ...state,
      pensionsBeneficiaryContacts: {
        ...state.pensionsBeneficiaryContacts,
        data: response,
        isLoading: false,
      },
    }),
  ),
  on(
    communicationsActions.loadPensionsBeneficiaryContactDetailsDataFailure,
    (state, { error }) => ({
      ...state,
      pensionsBeneficiaryContacts: {
        ...state.pensionsBeneficiaryContacts,
        data: null,
        isLoading: false,
        error,
      },
    }),
  ),
  on(communicationsActions.loadPensionsSmsMessageTemplatePreview, (state) => ({
    ...state,
    sendPensionsBeneficiarySmsModal: {
      ...state.sendPensionsBeneficiarySmsModal,
      selectedSmsTemplatePreview: '',
    },
  })),
  on(
    communicationsActions.loadPensionsSmsMessageTemplatePreviewSuccess,
    (state, { template }) => ({
      ...state,
      sendPensionsBeneficiarySmsModal: {
        ...state.sendPensionsBeneficiarySmsModal,
        selectedSmsTemplatePreview: template,
      },
    }),
  ),
  on(
    communicationsActions.loadPensionsSmsMessageTemplatePreviewFailure,
    (state) => ({
      ...state,
      sendPensionsBeneficiarySmsModal: {
        ...state.sendPensionsBeneficiarySmsModal,
        selectedSmsTemplatePreview: '',
      },
    }),
  ),
  on(communicationsActions.clearPensionsSmsMessageTemplatePreview, (state) => ({
    ...state,
    sendPensionsBeneficiarySmsModal: {
      ...state.sendPensionsBeneficiarySmsModal,
      selectedSmsTemplatePreview: '',
    },
  })),

  on(communicationsActions.sendSms, (state) => ({
    ...state,
    isSendingSms: true,
  })),
  on(
    communicationsActions.sendSmsSuccess,
    communicationsActions.sendSmsFailure,
    (state) => ({
      ...state,
      isSendingSms: false,
    }),
  ),
  on(
    communicationsActions.loadSMSMessageHistory,
    (state, { limit, offset, sort, searchData, complexSearchData }) => ({
      ...state,
      smsMessageHistory: {
        ...state.smsMessageHistory,
        limit,
        offset,
        isLoading: true,
        sort,
        searchData,
        complexSearchData,
      },
    }),
  ),
  on(
    communicationsActions.loadSMSMessageHistorySuccess,
    (state, { response: { total, totalPages, currentPage, data } }) => ({
      ...state,
      smsMessageHistory: {
        ...state.smsMessageHistory,
        data: [...state.smsMessageHistory.data, ...data],
        total,
        totalPages,
        currentPage,
        isLoading: false,
      },
    }),
  ),
  on(
    communicationsActions.loadSMSMessageHistoryFailure,
    (state, { error }) => ({
      ...state,
      smsMessageHistory: {
        ...state.smsMessageHistory,
        data: [],
        isLoading: false,
        error,
      },
    }),
  ),
  on(communicationsActions.clearSMSMessageHistory, (state) => ({
    ...state,
    smsMessageHistory: {
      ...initialPagedData,
    },
  })),
  on(communicationsActions.loadEducationAssistanceContactDetails, (state) => ({
    ...state,
    educationAssistanceBeneficiaryContacts: {
      ...state.educationAssistanceBeneficiaryContacts,
      data: [],
      isLoading: true,
    },
  })),
  on(
    communicationsActions.loadEducationAssistanceContactDetailsSuccess,
    (state, { response }) => ({
      ...state,
      educationAssistanceBeneficiaryContacts: {
        ...state.educationAssistanceBeneficiaryContacts,
        data: response,
        isLoading: false,
      },
    }),
  ),
  on(
    communicationsActions.loadEducationAssistanceContactDetailsFailure,
    (state, { error }) => ({
      ...state,
      educationAssistanceBeneficiaryContacts: {
        ...state.educationAssistanceBeneficiaryContacts,
        data: null,
        isLoading: false,
        error,
      },
    }),
  ),

  on(communicationsActions.startWhatsAppUnreadNotifications, (state) => ({
    ...state,
    isCreatingUnreadWhatsappConversationNotifications: true,
  })),
  on(
    communicationsActions.startWhatsAppUnreadNotificationsSuccess,
    communicationsActions.startWhatsAppUnreadNotificationsFailure,
    (state) => ({
      ...state,
      isCreatingUnreadWhatsappConversationNotifications: false,
    }),
  ),
  on(
    communicationsActions.updateWhatsAppUnreadNotifications,
    (state, { notification }) => ({
      ...state,
      whatsAppUnreadConversations: notification,
    }),
  ),
);

import { PipeTransform } from '@angular/core';

export const mapPipe = (mapper: (x: any) => any) => new LambdaPipe(mapper);

class LambdaPipe implements PipeTransform {
  constructor(private mapper: (originalValue: any) => any) {}

  transform(originalValue: any) {
    return this.mapper(originalValue);
  }
}

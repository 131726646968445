export enum MessageTypeEnum {
  PleaseCallMe = 1,
  InformationRequest = 2,
  Whatsapp = 3,
  ClaimStatusUpdateNotification = 5,
}

export const displayReadableMessageTypeEnum = (value: MessageTypeEnum) => {
  switch (value) {
    case MessageTypeEnum.PleaseCallMe:
      return 'Please Call Me';
    case MessageTypeEnum.InformationRequest:
      return 'Outstanding Information';
    case MessageTypeEnum.Whatsapp:
      return 'WhatsApp';
    case MessageTypeEnum.ClaimStatusUpdateNotification:
      return 'Claim Status Update Notification';
    default:
      return 'Unknown';
  }
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommunicationsEffects } from '@app/communications/state/communications.effects';
import { CommunicationsService } from '@app/communications/services/communications.service';
import { AuthenticationModule } from '@app/authentication/authentication.module';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import {
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { WhatsAppMessageTableDefinition } from '@app/communications/components/communication-messages-tables/table-definitions/whats-app-message.table-definition';
import { SmsMessageTableDefinition } from '@app/communications/components/communication-messages-tables/table-definitions/sms-message.table-definition';
import { CommunicationMessagesTablesComponent } from '@app/communications/components/communication-messages-tables/communication-messages-tables.component';
import { WhatsappChatComponent } from '@app/communications/shells/whatsapp-chat/whatsapp-chat.component';
import { WhatsAppConversationsListComponent } from '@app/communications/components/whatsapp-conversations-list/whatsapp-conversation-list.component';
import { WhatsAppConversationsListItemComponent } from '@app/communications/components/whatsapp-conversations-list-item/whatsapp-conversation-list-item.component';
import { WhatsappConversationComponent } from '@app/communications/shells/whatsapp-conversation/whatsapp-conversation.component';
import { SharedModule } from '@app/shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { ClaimSmsModalComponent } from '@app/communications/components/claim-sms-modal/claim-sms-modal.component';
import { ClaimCommunicationsComponent } from '@app/communications/components/claim-communications/claim-communications.component';
import { PensionsSmsModalComponent } from '@app/communications/components/pensions-sms-modal/pensions-sms-modal.component';
import { PensionsWhatsappModalComponent } from '@app/communications/components/pensions-whatsapp-modal/pensions-whatsapp-modal.component';
import { WhatsappChatHeadingComponent } from './components/whatsapp-chat-heading/whatsapp-chat-heading.component';
import * as fromCommunications from './state/communications.reducer';
import { CommunicationsRoutingModule } from './communications-routing.module';
import { ContactDisplayNameComponent } from './components/contact-full-name/contact-display-name.component';
import { WhatsappMessagesComponent } from './components/whatsapp-messages/whatsapp-messages.component';
import { ClaimWhatsappModalComponent } from './components/claim-whatsapp-modal/claim-whatsapp-modal.component';
import { WhatsappMessageInputComponent } from './components/whatsapp-message-input/whatsapp-message-input.component';
import { EmptyWhatsappChatComponent } from './components/empty-whatsapp-chat/empty-whatsapp-chat.component';
import { PensionsCommunicationsComponent } from './components/pensions-communications/pensions-communications.component';
import { MediaPlayerComponent } from './components/media-components/media-player/media-player.component';

@NgModule({
  declarations: [
    CommunicationMessagesTablesComponent,
    WhatsappChatComponent,
    WhatsAppConversationsListComponent,
    WhatsAppConversationsListItemComponent,
    WhatsappChatHeadingComponent,
    WhatsappConversationComponent,
    ContactDisplayNameComponent,
    WhatsappMessagesComponent,
    WhatsappMessageInputComponent,
    ClaimWhatsappModalComponent,
    ClaimSmsModalComponent,
    EmptyWhatsappChatComponent,
    ClaimCommunicationsComponent,
    PensionsWhatsappModalComponent,
    PensionsSmsModalComponent,
    PensionsCommunicationsComponent,
    MediaPlayerComponent,
  ],
  imports: [
    CommonModule,
    CommunicationsRoutingModule,
    EffectsModule.forFeature([CommunicationsEffects]),
    StoreModule.forFeature(
      fromCommunications.communicationsFeatureKey,
      fromCommunications.reducer,
    ),
    SharedModule,
    NgbNavModule,
    AuthenticationModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbDropdownModule,
    FileUploadModule,
    NgbTooltipModule,
  ],
  exports: [
    CommunicationMessagesTablesComponent,
    ClaimCommunicationsComponent,
    PensionsCommunicationsComponent,
  ],
  providers: [
    CommunicationsService,
    CommunicationsFacade,
    WhatsAppMessageTableDefinition,
    SmsMessageTableDefinition,
    FormGroupDirective,
  ],
})
export class CommunicationsModule {}

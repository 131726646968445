<ng-container *ngIf="message">
  <div
    *ngIf="message.hasMedia && message.isAudioMedia; else otherFileType"
    (click)="showPlayer()">
    <audio
      *ngIf="message.isAudioMedia && isVisible && isDownloaded"
      [src]="downloadAudioFile(message) | async"
      autoplay="autoplay"
      controls></audio>

    <span
      *ngIf="message.isAudioMedia && !isVisible && !isDownloaded"
      class="fem-icon fem-icon-play-fill file-icon"></span>

    <div
      *ngIf="isVisible && !isDownloaded"
      class="spinner-border spinner-border-sm text-primary"></div>
  </div>

  <ng-template #otherFileType>
    <span class="fem-icon fem-icon-draft file-icon"></span>
  </ng-template>
</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import { filterOutNulls } from '@app/core/helpers/array-helpers';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { SystemContact } from '@app/communications/models/system-contact';
import { Department } from '@app/communications/models/department.enum';
import { errorless } from '@app/core/helpers/errorless';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { PensionsBeneficiaryContactDetails } from '@app/communications/models/pensions-beneficiary-contact-details';
import { PensionsSmsModalComponent } from '@app/communications/components/pensions-sms-modal/pensions-sms-modal.component';
import { PensionsWhatsappModalComponent } from '@app/communications/components/pensions-whatsapp-modal/pensions-whatsapp-modal.component';

@Component({
  selector: 'app-pensions-communications',
  templateUrl: './pensions-communications.component.html',
  styleUrl: './pensions-communications.component.scss',
})
export class PensionsCommunicationsComponent
  extends AutoUnsubscription
  implements OnInit
{
  @Input()
  claimId: number;
  pensionsBeneficiaryContactsIsLoading$ =
    this.facade.pensionsBeneficiaryContactDetailsIsLoading$;
  pensionsBeneficiaryContacts$ = this.facade.pensionsBeneficiaryContactDetails$;
  systemContactsIsLoading$ = this.facade.systemContactDetailsIsLoading$;
  systemContacts$ = this.facade.systemContactDetails$;
  receivers$: Observable<string[]>;
  senders$: Observable<string[]>;
  isInitialLoad$ = combineLatest([
    this.pensionsBeneficiaryContacts$,
    this.systemContacts$,
  ]).pipe(
    map(
      ([beneficiaryContacts, systemContacts]) =>
        !beneficiaryContacts || !systemContacts,
    ),
    takeUntil(this.notifier),
  );

  constructor(
    private modalService: NgbModal,
    private facade: CommunicationsFacade,
  ) {
    super();
  }

  ngOnInit(): void {
    this.facade.loadPensionContactDetails(this.claimId);
    this.facade.loadSystemContacts();
    this.receivers$ = this.pensionsBeneficiaryContacts$.pipe(
      filterOutNulls,
      switchMap((contacts: PensionsBeneficiaryContactDetails[]) =>
        of(contacts.map((contact) => contact.phoneNumber)),
      ),
    );
    this.senders$ = this.systemContacts$.pipe(
      filterOutNulls,
      switchMap((contacts: SystemContact[]) =>
        of(
          contacts
            .filter((contact) =>
              contact.departments.includes(Department.Pensions),
            )
            .map((contact) => contact.phoneNumber),
        ),
      ),
    );
  }

  showSmsModal() {
    const modal = this.modalService.open(PensionsSmsModalComponent);
    modal.componentInstance.claimId = this.claimId;
    return errorless(modal.result);
  }

  openWhatsAppModal() {
    const modal = this.modalService.open(PensionsWhatsappModalComponent);
    return errorless(modal.result);
  }
}

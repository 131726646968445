import { Component, OnInit } from '@angular/core';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import {
  filter,
  map,
  pairwise,
  startWith,
  take,
  takeUntil,
} from 'rxjs/operators';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import {
  defaultOffset,
  maxLimit,
} from '@app/shared/helpers/pagination-helpers';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.scss'],
})
export class WhatsappChatComponent
  extends AutoUnsubscription
  implements OnInit
{
  myWhatsAppMessageHistory$ = this.commsFacade.userMessageHistory$.pipe(
    map((pagedData) => pagedData.data),
  );
  conversationsAreLoading$ = this.commsFacade.userMessageHistory$.pipe(
    map((pagedData) => pagedData.isLoading),
    pairwise(),
    filter(([prev, current]) => prev === true && current === false),
    map(([prev, current]) => current),
    take(1),
  );
  metadata: UserMessagingMetadata;
  showEmptyState = true;

  constructor(
    private commsFacade: CommunicationsFacade,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.commsFacade.loadUserMessagingMetadata();

    this.commsFacade.userMessagingMetadata$
      .pipe(
        filter((metadata) => !!metadata.data),
        take(1),
      )
      .subscribe((metadata) => {
        this.metadata = metadata.data;
        this.commsFacade.resetUserMessageHistory(
          metadata.data,
          defaultOffset,
          maxLimit,
        );
      });

    this.subscribeToConversationChanges();
  }

  subscribeToConversationChanges() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(new NavigationEnd(0, this.router.url, this.router.url)),
        pairwise(),
        map(([previous, current]: [NavigationEnd, NavigationEnd]) => {
          if (
            previous.urlAfterRedirects.includes(
              '/whatsapp-chat/conversations/',
            ) &&
            current.urlAfterRedirects.includes('/whatsapp-chat/conversations/')
          ) {
            const num = Number(previous.urlAfterRedirects.split('/')[4]);
            return num;
          }
          return null;
        }),
        filter((convId) => !!convId),
        takeUntil(this.notifier),
      )
      .subscribe((conversationId) => {
        this.commsFacade.markWhatsappConversationAsRead(conversationId);
      });
  }

  searchConversations(searchString: string) {
    if (this.metadata) {
      this.commsFacade.searchUserMessageHistory(this.metadata, searchString);
    }
  }

  onActivate() {
    setTimeout(() => {
      this.showEmptyState = false;
    });
  }

  onDeactivate() {
    setTimeout(() => {
      this.showEmptyState = true;
    });
  }
}

import { Department } from '@app/communications/models/department.enum';

export enum WhatsappTemplate {
  ClaimsMessageOptIn = 1,
  ClaimsMessageResumption = 2,
  PensionsBeneficiaryMessageOptIn = 3,
  PensionsBeneficiaryMessageResumption = 4,
  EducationAssistanceMessageOptIn = 5,
  EducationAssistanceMessageResumption = 6,
}

export const mapDepartmentToResumptionTemplate = (value: Department) => {
  switch (value) {
    case Department.Claims:
      return WhatsappTemplate.ClaimsMessageResumption;
    case Department.Pensions:
      return WhatsappTemplate.PensionsBeneficiaryMessageResumption;
    case Department.EAP:
      return WhatsappTemplate.EducationAssistanceMessageResumption;
    default:
      return WhatsappTemplate.ClaimsMessageResumption;
  }
};

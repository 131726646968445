<app-modal [title]="'Send SMS'">
  <app-circle-loader
    [loading]="
      (contactDetailsIsLoading$ | async) ||
      ((metadata$ | async)?.isLoading ?? false)
    ">
    <div class="container">
      <form [formGroup]="form">
        <div class="row">
          <div
            *ngIf="
              !((contactDetailsIsLoading$ | async) ?? false) && !hasValidContact
            "
            class="col-12">
            <app-callout [type]="'danger'">
              <label class="text-danger">
                The pension does not have valid contact details to send an SMS
                message to. Please update the contact details to proceed.
              </label>
            </app-callout>
          </div>
          <div
            *ngIf="
              !((metadata$ | async)?.isLoading ?? false) &&
              !hasValidSystemContact
            "
            class="col-12">
            <app-callout [type]="'danger'">
              <label class="text-danger">
                You do not have the permissions to send messages from a claim.
                Please contact IT if this is an error.
              </label>
            </app-callout>
          </div>
        </div>
        <div class="row" *ngIf="hasValidContact && hasValidSystemContact">
          <div class="col-12">
            <app-select-input
              [parentForm]="form"
              inputName="messageType"
              labelName="Type"
              appendLabel="true"
              [ellipsisStart]="200"
              [selectOptions]="messageTypeOptions"
              [flagAsRequired]="true">
            </app-select-input>
          </div>

          <div class="col-12">
            <app-select-input
              [appendLabel]="true"
              [inputName]="'pensionsBeneficiaryContactId'"
              [labelName]="'Recipient'"
              [parentForm]="form"
              [selectOptions]="contactDetailsOptions">
            </app-select-input>
          </div>
          <div class="col-12">
            <app-select-input
              [addDefaultOption]="true"
              [appendLabel]="true"
              [inputName]="'systemContactId'"
              [labelName]="'Sender'"
              [parentForm]="form"
              [selectOptions]="senderOptions">
            </app-select-input>
          </div>
          <div class="col-12">
            <app-text-display
              [labelName]="'SMS Preview'"
              [value]="messagePreview"
              [truncateText]="false">
            </app-text-display>
            <div class="message-count float-end">{{ characterCount }}/160</div>
          </div>
          <div class="col-6">
            <button class="btn btn-secondary" (click)="clear()">
              Clear All
            </button>
          </div>
          <div class="col-6 mt-2">
            <button
              class="btn btn-primary float-end"
              [disabled]="this.form.invalid || (isSending$ | async)"
              (click)="submit()">
              <div
                class="spinner-border spinner-border-sm mr-1"
                role="status"
                *ngIf="isSending$ | async"></div>
              {{ (isSending$ | async) ? 'Sending SMS' : 'Send SMS' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </app-circle-loader>
</app-modal>

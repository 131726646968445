import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TableDefinition } from '@app/shared/dashboard-table/table-definitions/table-definition';
import { TableColumnProp } from '@swimlane/ngx-datatable';
import {
  displayReadableMessageTypeEnum,
  MessageTypeEnum,
} from '@app/communications/models/message-type.enum';
import { CommunicationsFacade } from '@app/communications/state/communications.facade';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';
import {
  displayReadableMessageStatusEnum,
  MessageStatusEnum,
} from '@app/communications/models/message-status.enum';
import { SmsMessageTableDefinition } from './table-definitions/sms-message.table-definition';
import { WhatsAppMessageTableDefinition } from './table-definitions/whats-app-message.table-definition';

@Component({
  selector: 'app-communication-messages-table',
  templateUrl: './communication-messages-tables.component.html',
  styleUrls: ['./communication-messages-tables.component.scss'],
})
export class CommunicationMessagesTablesComponent implements OnInit, OnDestroy {
  @Input()
  senders: string[] = [];
  private _receivers: string[] = [];
  get receivers(): string[] {
    return this._receivers;
  }

  @Input()
  set receivers(value: string[]) {
    this._receivers = value;
    this.smsTableDefinition.updateReceivers(value);
    this.whatsAppTableDefinition.updateReceivers(value);
  }

  @Input()
  showSms = true;
  @Input()
  showSmsSender = false;
  @Input()
  showWhatsAppSender = false;
  @Output()
  openWhatsAppSender = new EventEmitter();
  @Output()
  openSMSSender = new EventEmitter();
  @ViewChild('typeTemplate', { static: true })
  typeTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true })
  headerTemplate: TemplateRef<any>;
  iconSize = IconSizeEnum.Large;

  constructor(
    public facade: CommunicationsFacade,
    public smsTableDefinition: SmsMessageTableDefinition,
    public whatsAppTableDefinition: WhatsAppMessageTableDefinition,
  ) {}

  tableDefinitions: TableDefinition<any>[];

  ngOnInit(): void {
    this.initializeTableDefinitions();
  }

  ngOnDestroy(): void {
    this.destroyTables();
  }

  destroyTables() {
    if (this.tableDefinitions) {
      this.tableDefinitions.forEach((td) => td.destroy());
    }
  }

  initializeTableDefinitions() {
    this.whatsAppTableDefinition.columns =
      this.whatsAppTableDefinition.columns.map((col) => ({
        ...col,
        cellTemplate: this.getTemplate(col.prop),
      }));
    this.smsTableDefinition.columns = this.smsTableDefinition.columns.map(
      (col) => ({
        ...col,
        cellTemplate: this.getTemplate(col.prop),
      }),
    );
    this.whatsAppTableDefinition.senders = this.senders;
    this.whatsAppTableDefinition.receivers = this._receivers;
    this.smsTableDefinition.senders = this.senders;
    this.smsTableDefinition.receivers = this._receivers;
    this.smsTableDefinition.headerTemplate = this.headerTemplate;
    this.whatsAppTableDefinition.headerTemplate = this.headerTemplate;
    this.tableDefinitions = [this.whatsAppTableDefinition];
    if (this.showSms) {
      this.tableDefinitions.push(this.smsTableDefinition);
    }
  }

  getTemplate(prop: TableColumnProp) {
    switch (prop) {
      case 'messageType':
        return this.typeTemplate;
      case 'status':
        return this.statusTemplate;
    }
  }

  displayReadableMessage(value: MessageTypeEnum) {
    return displayReadableMessageTypeEnum(value);
  }

  displayReadableStatusMessage(value: MessageStatusEnum) {
    return displayReadableMessageStatusEnum(value);
  }

  sendWhatsApp() {
    this.openWhatsAppSender.emit();
  }

  sendSMS() {
    this.openSMSSender.emit();
  }
}

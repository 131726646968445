<div class="list-container d-flex flex-column align-items-stretch">
  <form
    [formGroup]="form"
  >
    <div class="search-control-wrapper">
      <input
        [placeholder]="placeholderText"
        aria-label="Search"
        class="search-control"
        formControlName="search"
      />
      <app-icon
        class="input-addon"
        name="search"
      ></app-icon>
    </div>
  </form>
  <cdk-virtual-scroll-viewport class="scroll-container" itemSize="84">
    <app-whatsapp-conversations-list-item *cdkVirtualFor="let conversation of conversations"
                                          [conversationId]="conversation.conversationId"
                                          [firstName]="conversation.contactName"
                                          [surname]="conversation.contactSurname"
                                          [phoneNumber]="conversation.contactPhoneNumber"
                                          [isRead]="conversation.isRead"
    >
    </app-whatsapp-conversations-list-item>
  </cdk-virtual-scroll-viewport>
</div>

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageFormService } from '@app/communications/services/message-form.service';
import { FileUploader } from 'ng2-file-upload';
import { ToastService } from '@app/core/services/toast.service';
import { UntypedFormControl } from '@angular/forms';
import { AllowedWhatsAppFileTypes } from '@app/communications/models/allowed-whatsapp-file-types';
import { includes } from 'lodash';

@Component({
  selector: 'app-whatsapp-message-input',
  templateUrl: './whatsapp-message-input.component.html',
  styleUrls: ['./whatsapp-message-input.component.scss'],
})
export class WhatsappMessageInputComponent implements OnInit {
  messageMaxLength = 2000;
  formGroup = this.formService.form;
  messageControl = this.formService.messageControl;
  attachmentControl = this.formService.attachmentControl;
  fileControl = new UntypedFormControl();
  uploader: FileUploader = new FileUploader({ url: '' });
  allowedFileTypesList: string[];
  maxImageSize = 5242880;
  @ViewChild('fileInput', { static: true })
  fileInput: ElementRef;
  @Input()
  disabled = false;
  @Input()
  tooltip = '';

  @Input()
  sendingMessage = false;
  @Output()
  sendMessage = new EventEmitter();

  constructor(
    private formService: MessageFormService,
    private toastService: ToastService,
  ) {
    this.formService.setMessageMaxLength(this.messageMaxLength);
  }

  _allowedFileTypes: AllowedWhatsAppFileTypes;

  @Input()
  set allowedFileTypes(value: AllowedWhatsAppFileTypes) {
    if (value) {
      this._allowedFileTypes = value;
      this.allowedFileTypesList = [
        ...value.allowedImageTypes,
        ...value.allowedDocumentTypes,
        ...value.allowedAudioTypes,
        ...value.allowedVideoTypes,
        ...value.allowedContactTypes,
      ];
      this.uploader.setOptions({
        allowedMimeType: this.allowedFileTypesList,
        url: '',
      });
    }
  }

  get allowedFileTypes(): AllowedWhatsAppFileTypes {
    return this._allowedFileTypes;
  }

  ngOnInit(): void {}

  sendClicked() {
    if (this.formService.form.valid) {
      this.sendMessage.emit();
    }
  }

  setSelectedFile(files: File[]) {
    this.formService.attachmentControl.reset();

    if (files.length > 1) {
      this.toastService.showError('Only one file can be attached to a message');
      return;
    }

    if (files.length === 1) {
      this.loadFile(files[0], this.toastService);
    }
  }

  openFileDialog() {
    this.fileInput.nativeElement.click();
  }

  loadFile(file: File, toastService: ToastService) {
    const isImage =
      this.allowedFileTypes &&
      includes(
        this.allowedFileTypes.allowedImageTypes,
        file.type.toLowerCase(),
      );

    if (isImage && file.size > this.maxImageSize) {
      toastService.showError('Image cannot be larger than 5MB');
      this.fileControl.reset();
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const urlData = reader.result as any;
      this.formService.attachmentControl.setValue({
        imageBytes: urlData.split(',')[1] as ArrayBuffer,
        fileType: file.type,
        name: file.name,
      });
      this.fileControl.reset();
    };

    reader.onerror = () => {
      if (reader.error !== null) {
        toastService.showError('Could not load file');
      }
    };

    reader.readAsDataURL(file);
  }

  removeAttachment() {
    this.attachmentControl.reset();
    this.fileControl.reset();
  }
}

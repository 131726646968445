export enum PersonalRelationship {
  Unknown = 1,
  Spouse = 2,
  Parent = 3,
  Child = 4,
  Grandparent = 5,
  OtherFamily = 6,
  Colleague = 7,
  Friend = 8,
  Employee = 9,
  Other = 10,
  Widow = 11,
  Guardian = 12,
}

export const displayRelationship = (value: PersonalRelationship) => {
  switch (value) {
    case PersonalRelationship.Spouse:
      return 'Spouse';
    case PersonalRelationship.Parent:
      return 'Parent';
    case PersonalRelationship.Child:
      return 'Child';
    case PersonalRelationship.Grandparent:
      return 'Grandparent';
    case PersonalRelationship.OtherFamily:
      return 'Other Family';
    case PersonalRelationship.Colleague:
      return 'Colleague';
    case PersonalRelationship.Friend:
      return 'Friend';
    case PersonalRelationship.Employee:
      return 'Employee';
    case PersonalRelationship.Other:
      return 'Other';
    case PersonalRelationship.Guardian:
      return 'Guardian';
    case PersonalRelationship.Widow:
      return 'Widow';
    default:
      return 'Unknown';
  }
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from '@app/communications/models/message';
import { MessageStatusEnum } from '@app/communications/models/message-status.enum';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommunicationsService } from '@app/communications/services/communications.service';
import { AllowedWhatsAppFileTypes } from '@app/communications/models/allowed-whatsapp-file-types';
import { TableAction } from '@app/shared/dashboard-table/models/table-action';

@Component({
  selector: 'app-whatsapp-messages',
  templateUrl: './whatsapp-messages.component.html',
  styleUrls: ['./whatsapp-messages.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class WhatsappMessagesComponent implements OnInit {
  @Input()
  public messages: Message[] = [];

  @Output()
  public downloadMedia = new EventEmitter<number>();

  messageStatusEnum = MessageStatusEnum;

  mediaTypes: AllowedWhatsAppFileTypes;

  fileActions: TableAction<Message>[] = [
    {
      click: (message: Message) => {
        this.emitDownloadMedia(message.id);
      },
      action: 'download',
      label: 'Download',
      isVisible: (item: Message) => true,
    },
  ];
  constructor(private communicationsHttpService: CommunicationsService) {}

  ngOnInit(): void {
    this.loadMediaTypes();
  }

  emitDownloadMedia(id: number) {
    this.downloadMedia.emit(id);
  }

  loadMediaTypes = () => {
    this.communicationsHttpService
      .getAllowedWhatsAppFileTypes()
      .subscribe((types) => {
        this.mediaTypes = types;
      });
  };

  trackMessage = (index: number, message: Message) => message.id;
}

import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SendClaimMessageRequest } from '@app/communications/models/send-claim-message-request';

@Injectable()
export class ClaimSmsModalFormService {
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    const maxSmsLength = 160;
    this.form = this.formBuilder.group({
      messageType: [null, Validators.required],
      claimContact: [null, Validators.required],
      sender: [null, Validators.required],
      template: [null, Validators.maxLength(maxSmsLength)],
      characterCount: [0],
    });
    this.form.controls.template.disable();
  }

  populateRequestModelFromForm(
    claimCode: string,
    conversationId: number,
    message: string,
  ): SendClaimMessageRequest {
    const formValues = this.form.value;
    return {
      claimsContactId: formValues.claimContact,
      systemContactId: formValues.sender,
      claimCode,
      messageType: formValues.messageType,
      message,
      conversationId,
    };
  }

  clear() {
    this.form.controls.messageType.setValue(null);
    this.form.controls.claimContact.setValue(null);
    this.form.controls.sender.setValue(null);
    this.form.controls.characterCount.setValue(0);
  }

  patchSender(sender: number) {
    this.form.controls.sender.patchValue(sender);
  }

  patchClaimContact(claimContact: number) {
    this.form.controls.claimContact.patchValue(claimContact);
  }
}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SimpleSearchService } from '@app/shared/search/providers/simple-search.service';
import { AdvancedSearchService } from '@app/shared/search/providers/advanced-search.service';
import {
  defaultLimit,
  defaultOffset,
} from '@app/shared/helpers/pagination-helpers';
import { SortDirection, SortPropDir } from '@swimlane/ngx-datatable';
import { FilterParameterOperator } from '@app/shared/search/interfaces/filter-parameter';
import { UserMessagingMetadata } from '@app/communications/models/user-messaging-metadata';
import {
  ComplexSearchRequest,
  initialComplexSearchRequest,
  initialSearchRequest,
  SearchRequest,
} from '@app/shared/search/interfaces/search-request';
import { PagedApiData } from '@app/core/models/PagedApiData';
import { WhatsappMessageHistory } from '@app/communications/models/whatsapp-message-history';
import { take } from 'rxjs/operators';
import { MessageHistory } from '@app/communications/models/message-history';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';
import { SendWhatsAppMessageRequest } from '@app/communications/models/send-whatsapp-message-request';
import { Actions, ofType } from '@ngrx/effects';
import { SendClaimMessageRequest } from '@app/communications/models/send-claim-message-request';
import { WhatsappTemplate } from '@app/communications/models/whatsapp-template.enum';
import { SendPensionsBeneficiaryMessageRequest } from '@app/communications/models/send-pensions-beneficiary-message-request';
import { SendSmsRequest } from '@app/communications/models/send-sms-request';
import { SendEducationAssistanceMessageRequest } from '@app/communications/models/send-education-assistance-message-request';
import * as communicationsSelectors from './communications.selectors';
import * as communicationsActions from './communications.actions';
import { markWhatsappConversationAsRead } from './communications.actions';

@Injectable()
export class CommunicationsFacade {
  userMessagingMetadata$ = this.store.pipe(
    select(communicationsSelectors.getUserMessagingMetadataState),
  );
  userMessageHistory$ = this.store.pipe(
    select(communicationsSelectors.getUserMessageHistoryState),
  );
  conversationDetails$ = this.store.pipe(
    select(communicationsSelectors.getConversationDetails),
  );
  conversationDetailsIsLoading$ = this.store.pipe(
    select(communicationsSelectors.getConversationDetailsIsLoading),
  );

  messageHistory$ = this.store.pipe(
    select(communicationsSelectors.getMessageHistoryState),
  );
  messageHistoryIsLoading$ = this.store.pipe(
    select(communicationsSelectors.getMessageHistoryIsLoading),
  );
  whatsappConversationMessages$ = this.store.pipe(
    select(communicationsSelectors.getWhatsappConversationMessages),
  );
  whatsappConversationMessagesAreLoading$ = this.store.pipe(
    select(communicationsSelectors.getWhatsappConversationMessagesIsLoading),
  );
  whatsAppIsBeingSent$ = this.store.pipe(
    select(communicationsSelectors.getWhatsappIsBeingSent),
  );
  whatsAppSentSuccessfully$ = this.updates$.pipe(
    ofType(communicationsActions.sendWhatsAppMessageSuccess),
  );
  isStartingConversation$ = this.store.pipe(
    select(communicationsSelectors.getIsStartingConversation),
  );
  isSendingOptIn$ = this.store.pipe(
    select(communicationsSelectors.getIsSendingOptIn),
  );
  claimContactDetails$ = this.store.pipe(
    select(communicationsSelectors.getClaimContacts),
  );
  claimContactDetailsIsLoading$ = this.store.pipe(
    select(communicationsSelectors.getClaimContactsIsLoading),
  );
  selectedClaimSmsTemplatePreview$ = this.store.pipe(
    select(communicationsSelectors.getSelectedClaimSmsTemplatePreview),
  );

  allowedWhatsAppFileTypes$ = this.store.pipe(
    select(communicationsSelectors.getAllowedWhatsAppFileTypes),
  );
  templatedMessage$ = this.store.pipe(
    select(communicationsSelectors.getTemplatedMessage),
  );
  templateMessageIsSending$ = this.store.pipe(
    select(communicationsSelectors.getTemplateMessageIsSending),
  );
  systemContactDetails$ = this.store.pipe(
    select(communicationsSelectors.getSystemContacts),
  );
  systemContactDetailsIsLoading$ = this.store.pipe(
    select(communicationsSelectors.getSystemContactsIsLoading),
  );
  pensionsBeneficiaryContactDetails$ = this.store.pipe(
    select(communicationsSelectors.getPensionsBeneficiaryContacts),
  );
  pensionsBeneficiaryContactDetailsIsLoading$ = this.store.pipe(
    select(communicationsSelectors.getPensionsBeneficiaryContactsIsLoading),
  );
  selectedPensionsBeneficiarySmsTemplatePreview$ = this.store.pipe(
    select(
      communicationsSelectors.getSelectedPensionsBeneficiarySmsTemplatePreview,
    ),
  );
  isSendingSms$ = this.store.pipe(
    select(communicationsSelectors.getIsSendingSms),
  );
  smsMessageHistory$ = this.store.pipe(
    select(communicationsSelectors.getSMSMessageHistoryState),
  );
  smsMessageHistoryIsLoading$ = this.store.pipe(
    select(communicationsSelectors.getSMSMessageHistoryIsLoading),
  );

  educationAssistanceContactDetails$ = this.store.pipe(
    select(communicationsSelectors.getEducationAssistanceContacts),
  );
  educationAssistanceContactDetailsIsLoading$ = this.store.pipe(
    select(communicationsSelectors.getEducationAssistanceContactsIsLoading),
  );
  isCreatingUnreadWhatsappConversationNotifications = this.store.pipe(
    select(
      communicationsSelectors.getIsCreatingUnreadWhatsappConversationNotifications,
    ),
  );
  whatsappConversationNotifications$ = this.store.pipe(
    select(communicationsSelectors.getWhatsappConversationNotifications),
  );
  constructor(
    private store: Store,
    private simpleSearch: SimpleSearchService,
    private advancedSearch: AdvancedSearchService,
    private updates$: Actions,
  ) {}

  loadUserMessagingMetadata() {
    this.store.dispatch(communicationsActions.loadUserMessagingMetadata());
  }

  loadUserMessageHistory(
    metadata: UserMessagingMetadata,
    offset: number = defaultOffset,
    limit: number = defaultLimit,
    complexSearchData: ComplexSearchRequest = initialComplexSearchRequest,
    searchData: SearchRequest = initialSearchRequest,
    sort: SortPropDir = { dir: SortDirection.desc, prop: 'sentDate' },
  ) {
    this.store.dispatch(
      communicationsActions.loadUserMessageHistory({
        metadata,
        limit,
        offset,
        complexSearchData,
        searchData,
        sort,
      }),
    );
  }

  loadMoreUserMessageHistory(
    metadata: UserMessagingMetadata,
    offset: number = defaultOffset,
    limit: number = defaultLimit,
  ) {
    this.userMessageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<WhatsappMessageHistory>) =>
        this.loadUserMessageHistory(
          metadata,
          limit + offset,
          limit,
          data.complexSearchData,
          data.searchData,
          data.sort,
        ),
      );
  }

  resetUserMessageHistory(
    metadata: UserMessagingMetadata,
    offset: number = defaultOffset,
    limit: number = defaultLimit,
  ) {
    this.store.dispatch(communicationsActions.clearUserMessageHistory());
    this.userMessageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<WhatsappMessageHistory>) =>
        this.loadUserMessageHistory(
          metadata,
          offset,
          limit,
          data.complexSearchData,
          data.searchData,
          data.sort,
        ),
      );
  }

  sortUserMessageHistory(metadata: UserMessagingMetadata, sort: SortPropDir) {
    this.userMessageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<WhatsappMessageHistory>) =>
        this.loadUserMessageHistory(
          metadata,
          defaultOffset,
          defaultLimit,
          data.complexSearchData,
          data.searchData,
          sort,
        ),
      );
  }

  searchUserMessageHistory(
    metadata: UserMessagingMetadata,
    unformattedSearchData: string | Record<string, unknown>,
  ) {
    const isAdvancedSearch = typeof unformattedSearchData !== 'string';
    const filterParameters: Record<string, FilterParameterOperator> = {
      type: FilterParameterOperator.Equals,
    };
    const canSearch = isAdvancedSearch
      ? this.advancedSearch.validate(
          unformattedSearchData as Record<string, unknown>,
        )
      : this.simpleSearch.canSearch(unformattedSearchData as string);

    if (canSearch) {
      const searchData = isAdvancedSearch
        ? this.advancedSearch.createSearchRequest(
            unformattedSearchData as Record<string, unknown>,
            filterParameters,
          )
        : this.simpleSearch.doSearch(
            unformattedSearchData as string,
            'content',
          );
      this.userMessageHistory$
        .pipe(take(1))
        .subscribe((data: PagedApiData<WhatsappMessageHistory>) =>
          this.loadUserMessageHistory(
            metadata,
            defaultOffset,
            defaultLimit,
            data.complexSearchData,
            searchData,
            data.sort,
          ),
        );
    } else {
      this.resetUserMessageHistory(metadata);
    }
  }

  loadConversationDetails(conversationId: number) {
    this.store.dispatch(
      communicationsActions.loadConversationDetails({ conversationId }),
    );
  }

  loadMessageHistory(
    messageType: MessageTypeEnum[],
    senders: string[],
    receivers: string[],
    offset: number = defaultOffset,
    limit: number = defaultLimit,
    complexSearchData: ComplexSearchRequest = initialComplexSearchRequest,
    searchData: SearchRequest = initialSearchRequest,
    sort: SortPropDir = { dir: SortDirection.desc, prop: 'sentDate' },
  ) {
    this.store.dispatch(
      communicationsActions.loadMessageHistory({
        messageType,
        senders,
        receivers,
        limit,
        offset,
        complexSearchData,
        searchData,
        sort,
      }),
    );
  }

  loadMoreMessageHistory(
    senders: string[],
    receivers: string[],
    messageType: MessageTypeEnum[],
    offset: number = defaultOffset,
    limit: number = defaultLimit,
  ) {
    this.messageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<MessageHistory>) =>
        this.loadMessageHistory(
          messageType,
          senders,
          receivers,
          limit + offset,
          limit,
          data.complexSearchData,
          data.searchData,
          data.sort,
        ),
      );
  }

  resetMessageHistory(
    senders: string[],
    receivers: string[],
    messageType: MessageTypeEnum[],
  ) {
    this.store.dispatch(communicationsActions.clearMessageHistory());
    this.messageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<MessageHistory>) =>
        this.loadMessageHistory(
          messageType,
          senders,
          receivers,
          defaultOffset,
          defaultLimit,
          data.complexSearchData,
          data.searchData,
          data.sort,
        ),
      );
  }

  sortMessageHistory(
    senders: string[],
    receivers: string[],
    messageType: MessageTypeEnum[],
    sort: SortPropDir,
  ) {
    this.store.dispatch(communicationsActions.clearMessageHistory());
    this.messageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<MessageHistory>) =>
        this.loadMessageHistory(
          messageType,
          senders,
          receivers,
          defaultOffset,
          defaultLimit,
          data.complexSearchData,
          data.searchData,
          sort,
        ),
      );
  }

  loadWhatsappConversationMessages(
    conversationId: number,
    offset: number = defaultOffset,
    limit: number = defaultLimit,
  ) {
    this.store.dispatch(
      communicationsActions.loadWhatsappConversationMessages({
        conversationId,
        limit,
        offset,
      }),
    );
  }

  resetConversationMessages() {
    this.store.dispatch(
      communicationsActions.clearWhatsappConversationMessages(),
    );
  }

  sendWhatsappMessage(request: SendWhatsAppMessageRequest) {
    this.store.dispatch(communicationsActions.sendWhatsAppMessage({ request }));
  }

  loadClaimContactDetails(claimCode: string) {
    this.store.dispatch(
      communicationsActions.loadClaimContactDetails({
        claimCode,
      }),
    );
  }

  startClaimConversation(request: SendClaimMessageRequest) {
    this.store.dispatch(
      communicationsActions.startClaimWhatsAppConversation({
        request: {
          templateId: WhatsappTemplate.ClaimsMessageOptIn,
          ...request,
        },
      }),
    );
  }

  loadClaimSmsMessageTemplatePreview(
    messageTemplate: MessageTypeEnum,
    claimCode: string,
  ) {
    this.store.dispatch(
      communicationsActions.loadClaimSmsMessageTemplatePreview({
        messageTemplate,
        claimCode,
      }),
    );
  }

  clearClaimSmsMessageTemplatePreview() {
    this.store.dispatch(
      communicationsActions.clearClaimSmsMessageTemplatePreview(),
    );
  }

  sendClaimSms(
    request: SendClaimMessageRequest,
    shouldCreateConversation: boolean,
  ) {
    if (shouldCreateConversation) {
      this.store.dispatch(
        communicationsActions.startClaimSmsConversation({
          request,
        }),
      );
    } else {
      this.sendSMS({ ...request });
    }
  }

  sendSMS(request: SendSmsRequest) {
    this.store.dispatch(
      communicationsActions.sendSms({
        request,
      }),
    );
  }

  downloadMediaFile(id: number) {
    this.store.dispatch(
      communicationsActions.downloadMediaFile({
        id,
      }),
    );
  }

  getAllowedWhatsAppFileTypes() {
    this.store.dispatch(communicationsActions.getWhatsAppAllowedFileTypes());
  }

  getTemplatedMessage(template: WhatsappTemplate, contactId: number) {
    this.store.dispatch(
      communicationsActions.getTemplatedMessage({
        template,
        contactId,
      }),
    );
  }

  resetTemplatedMessage() {
    this.store.dispatch(communicationsActions.resetTemplatedMessage());
  }

  sendTemplatedMessage(conversationId: number, template: WhatsappTemplate) {
    this.store.dispatch(
      communicationsActions.sendTemplateMessage({
        conversationId,
        templateId: template,
      }),
    );
  }

  loadSystemContacts() {
    this.store.dispatch(communicationsActions.loadSystemContactDetails());
  }

  markWhatsappConversationAsRead(conversationId: number) {
    this.store.dispatch(markWhatsappConversationAsRead({ conversationId }));
  }

  sendPensionsBeneficiarySms(
    request: SendPensionsBeneficiaryMessageRequest,
    shouldCreateConversation: boolean,
  ) {
    if (shouldCreateConversation) {
      this.store.dispatch(
        communicationsActions.startPensionsBeneficiarySmsConversation({
          request,
        }),
      );
    } else {
      this.sendSMS({ ...request });
    }
  }

  loadPensionContactDetails(claimId: number) {
    this.store.dispatch(
      communicationsActions.loadPensionsBeneficiaryContactDetails({
        claimId,
      }),
    );
  }

  startPensionsBeneficiaryConversation(
    request: SendPensionsBeneficiaryMessageRequest,
  ) {
    this.store.dispatch(
      communicationsActions.startPensionsBeneficiaryWhatsAppConversation({
        request: {
          templateId: WhatsappTemplate.PensionsBeneficiaryMessageOptIn,
          ...request,
        },
      }),
    );
  }

  loadPensionsBeneficiarySmsMessageTemplatePreview(
    messageTemplate: MessageTypeEnum,
    claimId: number,
  ) {
    this.store.dispatch(
      communicationsActions.loadPensionsSmsMessageTemplatePreview({
        messageTemplate,
        claimId,
      }),
    );
  }

  clearPensionsSmsMessageTemplatePreview() {
    this.store.dispatch(
      communicationsActions.clearPensionsSmsMessageTemplatePreview(),
    );
  }

  loadSMSMessageHistory(
    messageType: MessageTypeEnum[],
    senders: string[],
    receivers: string[],
    offset: number = defaultOffset,
    limit: number = defaultLimit,
    complexSearchData: ComplexSearchRequest = initialComplexSearchRequest,
    searchData: SearchRequest = initialSearchRequest,
    sort: SortPropDir = { dir: SortDirection.desc, prop: 'sentDate' },
  ) {
    this.store.dispatch(
      communicationsActions.loadSMSMessageHistory({
        messageType,
        senders,
        receivers,
        limit,
        offset,
        complexSearchData,
        searchData,
        sort,
      }),
    );
  }

  loadMoreSMSMessageHistory(
    senders: string[],
    receivers: string[],
    messageType: MessageTypeEnum[],
    offset: number = defaultOffset,
    limit: number = defaultLimit,
  ) {
    this.messageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<MessageHistory>) =>
        this.loadSMSMessageHistory(
          messageType,
          senders,
          receivers,
          limit + offset,
          limit,
          data.complexSearchData,
          data.searchData,
          data.sort,
        ),
      );
  }

  resetSMSMessageHistory(
    senders: string[],
    receivers: string[],
    messageType: MessageTypeEnum[],
  ) {
    this.store.dispatch(communicationsActions.clearSMSMessageHistory());
    this.messageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<MessageHistory>) =>
        this.loadSMSMessageHistory(
          messageType,
          senders,
          receivers,
          defaultOffset,
          defaultLimit,
          data.complexSearchData,
          data.searchData,
          data.sort,
        ),
      );
  }

  sortSMSMessageHistory(
    senders: string[],
    receivers: string[],
    messageType: MessageTypeEnum[],
    sort: SortPropDir,
  ) {
    this.store.dispatch(communicationsActions.clearSMSMessageHistory());
    this.messageHistory$
      .pipe(take(1))
      .subscribe((data: PagedApiData<MessageHistory>) =>
        this.loadSMSMessageHistory(
          messageType,
          senders,
          receivers,
          defaultOffset,
          defaultLimit,
          data.complexSearchData,
          data.searchData,
          sort,
        ),
      );
  }

  loadEducationAssistanceContactDetails(beneficiaryId: number) {
    this.store.dispatch(
      communicationsActions.loadEducationAssistanceContactDetails({
        beneficiaryId,
      }),
    );
  }

  startEducationAssistanceConversation(
    request: SendEducationAssistanceMessageRequest,
  ) {
    this.store.dispatch(
      communicationsActions.startEducationAssistanceWhatsAppConversation({
        request: {
          templateId: WhatsappTemplate.EducationAssistanceMessageOptIn,
          ...request,
        },
      }),
    );
  }
}

import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SendClaimMessageRequest } from '@app/communications/models/send-claim-message-request';
import { MessageTypeEnum } from '@app/communications/models/message-type.enum';

@Injectable()
export class ClaimWhatsappModalFormService {
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      claimContact: ['', Validators.required],
      sender: ['', Validators.required],
    });
  }

  populateRequestModelFromForm(claimCode: string): SendClaimMessageRequest {
    const formValues = this.form.value;
    return {
      claimsContactId: formValues.claimContact,
      systemContactId: formValues.sender,
      claimCode,
      messageType: MessageTypeEnum.Whatsapp,
    };
  }

  patchClaimContact(value: number) {
    this.form.controls.claimContact.patchValue(value);
  }

  patchSender(value: number) {
    this.form.controls.sender.patchValue(value);
  }
}
